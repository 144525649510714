import { createStyles, Image, UnstyledButton } from "@mantine/core";
import React from "react";
import { AppContext } from "../Context";
import MachineIn from "./MachineIn";
import Jimmy from "../Assets/Images/BurgerJimmy.png";

const useStyle = createStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 8,
  },
  stakingWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 30,
    [theme.fn.smallerThan("md")]: {
      flexDirection: "column",
    },
  },
  machine: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: 30,
    [theme.fn.smallerThan("md")]: {
      flexDirection: "column",
    },
  },
}));

const Machine = () => {
  const { classes } = useStyle();
  const { isLoading } = React.useContext(AppContext);

  return (
    <div className={classes.root}>
      <div className={classes.stakingWrapper}>
        <div className={classes.machine}>
          <MachineIn />
          <UnstyledButton
            style={{
              border: "1px solid black",
              backgroundColor: "yellow",
              width: 100,
              borderRadius: 100,
              textAlign: "center",
              padding: 16,
            }}
          >
            Activate Burger Machine
          </UnstyledButton>
        </div>
        <div>
          <Image src={Jimmy} style={{ maxWidth: 400 }} />
        </div>
      </div>
    </div>
  );
};

export default Machine;
