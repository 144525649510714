import {
  Button,
  Center,
  createStyles,
  Image,
  Loader,
  Overlay,
  ScrollArea,
  SimpleGrid,
  Skeleton,
  Text,
} from "@mantine/core";
import * as web3 from "@solana/web3.js";
import React from "react";
import { AppContext } from "../Context";
import NftItem from "./NftItem";

const useStyle = createStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 8,
  },
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 12,
    paddingBottom: 12,
    border: `1px solid ${"black"}`,
    borderRadius: 12,
    maxHeight: 65,
    minHeight: 65,
    backgroundColor: "white",
  },
  selection: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 16,
    border: `1px solid ${"black"}`,
    borderRadius: 12,
    gap: 8,
  },
  balanceWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    flexDirection: "column",
  },
  walletText: {
    fontSize: 15,
    fontWeight: 700,
    textTransform: "uppercase",
    width: "100%",
  },
  balance: {
    fontSize: 13,
    fontWeight: 700,
    textTransform: "uppercase",
    [theme.fn.smallerThan("900")]: {
      fontSize: 8,
      fontWeight: 600,
    },
  },
  balanceNumber: {
    fontSize: 13,
    fontWeight: 700,
    textTransform: "uppercase",
    [theme.fn.smallerThan("900")]: {
      fontSize: 18,
      fontWeight: 700,
      lineHeight: 1,
    },
  },
  button: {
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 12,
    fontSize: 15,
    fontWeight: 800,
    textTransform: "uppercase",
    textAlign: "center",
    ":disabled": {
      border: `1px solid ${"black"}`,
      backgroundColor: "white",
      color: "black",
    },
    width: 120,
    [theme.fn.smallerThan("900")]: {
      fontSize: 12,
    },
  },
  buttonAll: {
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 12,
    fontSize: 15,
    fontWeight: 800,
    textTransform: "uppercase",
    textAlign: "center",
    ":disabled": {
      border: `1px solid ${"black"}`,
      backgroundColor: "white",
      color: "black",
    },
    [theme.fn.smallerThan("900")]: {
      fontSize: 12,
    },
  },
  buttonActive: {
    backgroundColor: "black",
    color: "white",
    cursor: "pointer",
  },
  buttonWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 8,
  },
}));

const StakeIn = () => {
  const { classes, cx } = useStyle();
  const {
    nfts,
    user,
    stake,
    HASHLIST,
    staked,
    handleDB,
    TRANSACTION_STAKE_ADD,
    getNfts,
    isLoading,
    setIsLoading,
  } = React.useContext(AppContext);
  const [selected, setSelected] = React.useState([]);
  const [items, setItems] = React.useState([]);

  const select = (address) => {
    let current = selected.filter((item) => item === address);
    if (current.length > 0) {
      setSelected(selected.filter((item) => item !== address));
    } else {
      setSelected(() => [...selected, address]);
    }
  };

  React.useEffect(() => {
    if (nfts) {
      if (nfts.length !== 0) {
        let currentInfo = staked.map((stk) => {
          return stk.nft
        });
        const uris = nfts
          .filter((item) => HASHLIST.includes(item.mintAddress.toBase58()))
          .filter((item) => !currentInfo.includes(item.mintAddress.toBase58()))
          .map((nft) => {
            return nft.uri;
          });
        Promise.all(
          uris
            .filter((uri) => uri.startsWith("http"))
            .map(async (uri) => {
              let res = await fetch(uri);
              let json = await res.json();
              json.uri = uri;
              return json;
            })
        )
          .then((json) => {
            setItems(json);
          })
          .catch((err) => {
            console.log(err);
            setItems([]);
          });
      }
    }
  }, [nfts, staked]);

  const submitNonCustodial = async (all) => {
    let currentInfo = staked.map((stk) => {
      return {
        nft: stk.nft,
        stakeName: stk.stakeName,
        updatedAt: stk.updatedAt,
      };
    });
    if (all) {
      let full = items.map((nft) => {
        return nfts.filter((item) => item?.uri === nft?.uri)[0]
        ?.mintAddress?.toBase58()
      });
      full.map((item) => {
        currentInfo.push({
          nft: item,
          stakeName: "SAKE",
          updatedAt: Date.now(),
        });
      });
    } else {
      selected.map((item) => {
        currentInfo.push({
          nft: item,
          stakeName: "SAKE",
          updatedAt: Date.now(),
        });
      });
    }
    let body = {
      wallet: user,
      info: currentInfo,
    };
    handleDB(body, user)
      .then(async (ok) => {
          await getNfts();
          setSelected([]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const submit = async () => {
  //   let body = [];
  //   selected.map((item) => {
  //     body.push({ nft: item });
  //   });
  //   TRANSACTION_STAKE_ADD(body, stake)
  //     .then((ok) => {
  //       if (ok) {
  //         let currentInfo = staked.map((stk) => {
  //           return {
  //             nft: stk.nft,
  //             stakeName: stk.stakeName,
  //             updatedAt: stk.updatedAt,
  //           };
  //         });
  //         selected.map((item) => {
  //           currentInfo.push({
  //             nft: item,
  //             stakeName: "SAKE",
  //             updatedAt: Date.now(),
  //           });
  //         });
  //         let body = {
  //           wallet: user,
  //           info: currentInfo,
  //         };
  //         handleDB(body, user)
  //           .then(async (ok) => {
  //             setTimeout( async () => {
  //               await getNfts();
  //               setSelected([]);
  //             }, 2000);
  //           })
  //           .catch((err) => {
  //             console.log(err);
  //           });
  //       } else {
  //         setIsLoading(false);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  return (
    <div className={classes.root}>
      <Skeleton visible={isLoading} className={classes.header}>
        <Text className={classes.walletText}>
          WALLET {user ? user.substring(0, 5) + "..." : "..."}
        </Text>
        {/* <div className={classes.balanceWrapper}>
            <Text className={classes.balance}>BALANCE:</Text>
            <Text className={classes.balanceNumber}>
              {stakingBalance ? stakingBalance : 0}{" "}
              {stake ? stake.prize.mintName : "$TOKEN"}
            </Text>
          </div> */}
      </Skeleton>
      <Skeleton visible={isLoading} className={classes.selection}>
        <ScrollArea style={{ paddingRight: 16, minHeight: 150, width: "100%" }}>
          {items && items.length === 0 && (
            <Center>
              <Text>0 items to stake</Text>
            </Center>
          )}
          <SimpleGrid
            cols={4}
            spacing={"md"}
            breakpoints={[
              { maxWidth: 900, cols: 3, spacing: "sm" },
              { minWidth: 1920, cols: 4, spacing: "lg" },
            ]}
          >
            {items &&
              items.length !== 0 &&
              items.map((nft, index) => (
                <NftItem
                  action={() =>
                    select(
                      nfts
                        ?.filter((item) => item?.uri === nft?.uri)[0]
                        ?.mintAddress?.toBase58()
                    )
                  }
                  selected={selected.includes(
                    nfts
                      ?.filter((item) => item?.uri === nft?.uri)[0]
                      ?.mintAddress?.toBase58()
                  )}
                  key={index}
                  nft={nft}
                />
              ))}
            {!items && <Loader />}
          </SimpleGrid>
        </ScrollArea>
        <div className={classes.buttonWrapper}>
          <Button
            disabled={selected.length === 0}
            onClick={() => submitNonCustodial(false)}
            className={cx(classes.button, {
              [classes.buttonActive]: selected.length > 0,
            })}
          >
            Stake
          </Button>
          <Button
            disabled={items.length === 0}
            onClick={() => submitNonCustodial(true)}
            className={cx(classes.buttonAll, {
              [classes.buttonActive]: items.length !== 0,
            })}
          >
            Stake All
          </Button>
        </div>
      </Skeleton>
    </div>
  );
};

export default StakeIn;
