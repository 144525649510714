import React from "react";
import idl from "../Helpers/idl.json";
import * as anchor from "@project-serum/anchor";
import * as web3 from "@solana/web3.js";
import { AccountLayout, TOKEN_PROGRAM_ID, Token } from "@solana/spl-token";
import {
  useAnchorWallet,
  useConnection,
  useWallet,
} from "@solana/wallet-adapter-react";
import { getMintDecimals } from "@project-serum/serum/lib/market";

export const Workspace = React.createContext({});
export const Workstorage = ({ children }) => {
  const preflightCommitment = "processed";
  const commitment = "processed";
  const programID = "6786Dbg7fxJJtEhoXW5H11EwbMfb8G9YdLcYUWiamMPw";
  const { connection } = useConnection();
  const user = useWallet();
  const wallet = useAnchorWallet();

  const makeStakeVaultTx = async (stakePoolName, workspace) => {
    const stake = String(stakePoolName);
    const instruction = [];
    const signers = [];
    let [prize, bump] = await web3.PublicKey.findProgramAddress(
      [Buffer.from("prize"), Buffer.from(stake)],
      new web3.PublicKey(workspace.programID)
    );
    const createVault = await workspace.program.methods
      .createMissionVault(stake, bump)
      .accounts({
        prize: prize,
        operator: user.publicKey,
        systemProgram: web3.SystemProgram.programId,
      })
      .instruction();
    instruction.push(createVault);
    return { instruction, signers };
  };

  const makeStakeDestroyTx = async (stake, workspace) => {
    const stakePool = String(stake.stakeName);
    const instruction = [];
    const signers = [];

    let [prize, bump] = await web3.PublicKey.findProgramAddress(
      [Buffer.from("prize"), Buffer.from(stakePool)],
      new web3.PublicKey(workspace.programID)
    );

    const destroyVaults = await workspace.program.methods
      .closeMissionVaults()
      .accounts({
        prize: prize,
        operator: user.publicKey,
      })
      .instruction();

    instruction.push(destroyVaults);
    return { instruction, signers };
  };

  const makeStakeDepositTx = async (stake, workspace, amount) => {
    const mintAddress = new web3.PublicKey(stake.prize.mintAddress);
    const stakePool = String(stake.stakeName);
    const instruction = [];
    const signers = [];

    let [prize, bump] = await web3.PublicKey.findProgramAddress(
      [Buffer.from("prize"), Buffer.from(stakePool)],
      new web3.PublicKey(workspace.programID)
    );

    const userTokenAccount = await connection.getParsedTokenAccountsByOwner(
      user.publicKey,
      { mint: mintAddress }
    );
    const poolTokenAccounts = await connection.getParsedTokenAccountsByOwner(
      prize,
      { mint: mintAddress }
    );
    const rent = await connection.getMinimumBalanceForRentExemption(
      AccountLayout.span
    );
    let token_to;
    if (poolTokenAccounts.value.length === 0) {
      const poolTokenAccount = web3.Keypair.generate();
      instruction.push(
        web3.SystemProgram.createAccount({
          fromPubkey: user.publicKey,
          newAccountPubkey: poolTokenAccount.publicKey,
          lamports: rent,
          space: AccountLayout.span,
          programId: TOKEN_PROGRAM_ID,
        })
      );
      instruction.push(
        Token.createInitAccountInstruction(
          TOKEN_PROGRAM_ID,
          mintAddress,
          poolTokenAccount.publicKey,
          prize
        )
      );
      token_to = poolTokenAccount;
      signers.push(token_to);
    } else {
      token_to = poolTokenAccounts.value[0].pubkey;
    }
    let decimals = await getMintDecimals(connection, mintAddress);
    instruction.push(
      Token.createTransferCheckedInstruction(
        TOKEN_PROGRAM_ID,
        userTokenAccount.value[0].pubkey,
        mintAddress,
        poolTokenAccounts.value.length === 0 ? token_to.publicKey : token_to,
        user.publicKey,
        [],
        amount * 10 ** decimals,
        decimals
      )
    );
    return { instruction, signers };
  };

  const stakeTx = async (stakePoolName, mintaddress, workspace) => {
    const mint = new web3.PublicKey(mintaddress);
    const stake = String(stakePoolName);
    const instruction = [];
    const signers = [];

    let [prize, bump] = await web3.PublicKey.findProgramAddress(
      [Buffer.from("prize"), Buffer.from(stake)],
      new web3.PublicKey(workspace.programID)
    );

    const userTokenAccount = await connection.getParsedTokenAccountsByOwner(
      user.publicKey,
      { mint: mint }
    );

    const poolTokenAccounts = await connection.getParsedTokenAccountsByOwner(
      prize,
      { mint: mint }
    );

    const tokenAcc = userTokenAccount.value[0].pubkey;
    const rent = await connection.getMinimumBalanceForRentExemption(
      AccountLayout.span
    );

    let token_to;

    if (poolTokenAccounts.value.length === 0) {
      const poolTokenAccount = web3.Keypair.generate();
      instruction.push(
        web3.SystemProgram.createAccount({
          fromPubkey: user.publicKey,
          newAccountPubkey: poolTokenAccount.publicKey,
          lamports: rent,
          space: AccountLayout.span,
          programId: TOKEN_PROGRAM_ID,
        })
      );
      instruction.push(
        Token.createInitAccountInstruction(
          TOKEN_PROGRAM_ID,
          mint,
          poolTokenAccount.publicKey,
          prize
        )
      );
      token_to = poolTokenAccount;
      signers.push(token_to);
    } else {
      token_to = poolTokenAccounts.value[0].pubkey;
    }

    const result = await workspace.program.methods
      .loan()
      .accounts({
        prize: prize,
        operator: user.publicKey,
        mint: mint,
        nftFrom: tokenAcc,
        nftTo:
          poolTokenAccounts.value.length === 0 ? token_to.publicKey : token_to,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: web3.SystemProgram.programId,
      })
      .instruction();

    instruction.push(result);

    return { instruction, signers };
  };

  const unstakeTx = async (stakePoolName, mintaddress, workspace) => {
    const mint = new web3.PublicKey(mintaddress);
    const stake = String(stakePoolName);
    const instruction = [];
    const signers = [];

    let pool = await web3.PublicKey.findProgramAddress(
      [Buffer.from("prize"), Buffer.from(stake)],
      new web3.PublicKey(programID)
    );

    const userTokenAccount = await connection.getParsedTokenAccountsByOwner(
      user.publicKey,
      { mint: mint }
    );

    const poolTokenAccounts = await connection.getParsedTokenAccountsByOwner(
      pool[0],
      { mint: mint }
    );

    let tokenAcc;
    const rent = await connection.getMinimumBalanceForRentExemption(
      AccountLayout.span
    );

    if (userTokenAccount.value.length === 0) {
      const userAta = web3.Keypair.generate();
      instruction.push(
        web3.SystemProgram.createAccount({
          fromPubkey: user.publicKey,
          newAccountPubkey: userAta.publicKey,
          lamports: rent,
          space: AccountLayout.span,
          programId: TOKEN_PROGRAM_ID,
        })
      );
      instruction.push(
        Token.createInitAccountInstruction(
          TOKEN_PROGRAM_ID,
          mint,
          userAta.publicKey,
          user.publicKey
        )
      );
      tokenAcc = userAta.publicKey;
      signers.push(userAta);
    } else {
      tokenAcc = userTokenAccount.value[0].pubkey;
    }

    const token_to = poolTokenAccounts.value[0].pubkey;
    const result = await workspace.program.methods
      .unloan()
      .accounts({
        prize: pool[0],
        operator: user.publicKey,
        mint: mint,
        nftFrom: token_to,
        nftTo: tokenAcc,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: web3.SystemProgram.programId,
      })
      .instruction();
    instruction.push(result);

    const close = await workspace.program.methods
      .closeTokenAccount()
      .accounts({
        prize: pool[0],
        tokenAcc: token_to,
        operator: user.publicKey,
        tokenProgram: TOKEN_PROGRAM_ID,
      })
      .instruction();

    instruction.push(close);

    return { instruction, signers };
  };

  const makeStakeClaimTx = async (stake, workspace, amount) => {
    const stakePool = String(stake.stakeName);
    const mintAddress = new web3.PublicKey(stake.prize.mintAddress);
    let instruction = [];
    let signers = [];

    let pool = await web3.PublicKey.findProgramAddress(
      [Buffer.from("prize"), Buffer.from(stakePool)],
      new web3.PublicKey(programID)
    );

    let decimals = await getMintDecimals(connection, mintAddress);

    const newAmount = new anchor.BN(amount * 10 ** decimals);

    const userTokenAccount = await connection.getParsedTokenAccountsByOwner(
      user.publicKey,
      { mint: mintAddress }
    );
    const poolTokenAccounts = await connection.getParsedTokenAccountsByOwner(
      pool[0],
      { mint: mintAddress }
    );

    let toPkey;
    if (userTokenAccount.value.length === 0) {
      const rent = await connection.getMinimumBalanceForRentExemption(
        AccountLayout.span
      );
      let to = web3.Keypair.generate();
      toPkey = to.publicKey;
      instruction.push(
        web3.SystemProgram.createAccount({
          fromPubkey: user.publicKey,
          newAccountPubkey: toPkey,
          lamports: rent,
          space: AccountLayout.span,
          programId: TOKEN_PROGRAM_ID,
        })
      );
      instruction.push(
        Token.createInitAccountInstruction(
          TOKEN_PROGRAM_ID,
          mintAddress,
          toPkey,
          user.publicKey
        )
      );
      signers.push(to);
    } else {
      toPkey = userTokenAccount.value[0].pubkey;
    }

    const claimToken = await workspace.program.methods
      .claimToken(newAmount)
      .accounts({
        prize: pool[0],
        operator: user.publicKey,
        mint: mintAddress,
        nftFrom: poolTokenAccounts.value[0].pubkey,
        nftTo: toPkey,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: web3.SystemProgram.programId,
      })
      .instruction();
    instruction.push(claimToken);

    return { instruction, signers };
  };

  const [workspace, setWorkspace] = React.useState();

  React.useEffect(() => {
    if (user.connected) {
      const provider = new anchor.AnchorProvider(connection, wallet, {
        preflightCommitment,
        commitment,
      });
      const program = new anchor.Program(idl, programID, provider);
      const workspace = {
        wallet,
        connection,
        provider,
        program,
        commitment,
        programID,
      };
      setWorkspace(workspace);
    }
  }, [user]);

  return (
    <Workspace.Provider
      value={{
        workspace,
        stakeTx,
        unstakeTx,
        makeStakeVaultTx,
        makeStakeClaimTx,
        makeStakeDepositTx,
        makeStakeDestroyTx,
      }}
    >
      {children}
    </Workspace.Provider>
  );
};
