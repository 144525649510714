import { createStyles, Image } from "@mantine/core";
import React from "react";
import { AppContext } from "../Context";

const useStyle = createStyles((theme, selected) => ({
  root: {},
  selection: {
    backgroundColor: "black",
    borderRadius: 12,
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    opacity: 0.1,
  },
}));

const NftItem = ({ nft, action, selected, small }) => {
  const { classes, theme } = useStyle();

  return (
    <div
      style={{
        backgroundColor: "white",
        border: selected === false ? "2px solid white" : "2px solid #EFB852",
        borderRadius: 13,
        position: "relative",
        transition: "border 100ms ease",
        maxWidth: small ? 70 : 100,
      }}
      onClick={action}
    >
      <Image
        src={nft.image}
        styles={{
          image: {
            borderRadius: 12,
            width: 88,
          },
        }}
      />
      {selected && <div className={classes.selection} />}
    </div>
  );
};

export default NftItem;
