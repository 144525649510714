import {
  Button,
  createStyles,
  Flex,
  Loader,
  ScrollArea,
  SimpleGrid,
  Text,
  Overlay,
  Skeleton,
  Center,
} from "@mantine/core";
import React from "react";
import { AppContext } from "../Context";
import * as web3 from "@solana/web3.js";
import NftItem from "./NftItem";
import { useMediaQuery } from "@mantine/hooks";

const useStyle = createStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 8,
  },
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 12,
    paddingBottom: 12,
    border: `1px solid ${"black"}`,
    borderRadius: 12,
    maxHeight: 65,
    minHeight: 65,
    backgroundColor: "white",
    [theme.fn.smallerThan("900")]: {
      maxHeight: "fit-content",
      minHeight: 65,
      flexDirection: "column",
    },
  },
  selection: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 16,
    border: `1px solid ${"black"}`,
    borderRadius: 12,
    gap: 8,
  },
  balanceWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    flexDirection: "column",
  },
  walletText: {
    fontSize: 15,
    lineHeight: 1,
    fontWeight: 700,
    textTransform: "uppercase",
    width: "100%",
    [theme.fn.smallerThan("900")]: {
      fontSize: 11,
      width: "fit-content",
    },
  },
  balance: {
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1,
    textTransform: "uppercase",
    [theme.fn.smallerThan("900")]: {
      fontSize: 11,
    },
  },
  balanceNumber: {
    fontSize: 13,
    fontWeight: 700,
    textTransform: "uppercase",
    [theme.fn.smallerThan("900")]: {
      fontSize: 18,
      fontWeight: 700,
      lineHeight: 1,
    },
  },
  button: {
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 12,
    fontSize: 15,
    fontWeight: 800,
    textTransform: "uppercase",
    textAlign: "center",
    ":disabled": {
      border: `1px solid ${"black"}`,
      backgroundColor: "white",
      color: "black",
    },
    width: 120,
    [theme.fn.smallerThan("900")]: {
      fontSize: 12,
    },
  },
  buttonAll: {
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 12,
    fontSize: 15,
    fontWeight: 800,
    textTransform: "uppercase",
    textAlign: "center",
    ":disabled": {
      border: `1px solid ${"black"}`,
      backgroundColor: "white",
      color: "black",
    },
    [theme.fn.smallerThan("900")]: {
      fontSize: 12,
    },
  },
  buttonActive: {
    backgroundColor: "black",
    color: "white",
    cursor: "pointer",
  },
  buttonWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 8,
  },
}));

const StakeOut = () => {
  const { classes, cx } = useStyle();
  const match = useMediaQuery("(max-width: 900px)");
  const {
    user,
    nfts,
    HASHLIST,
    stake,
    staked,
    handleDB,
    setIsLoading,
    TRANSACTION_STAKE_CLAIM,
    getBalance,
    getNfts,
    isLoading,
  } = React.useContext(AppContext);

  const [total, setTotal] = React.useState(0);
  const [selected, setSelected] = React.useState([]);
  const [stakedItems, setStakedItems] = React.useState([]);

  React.useEffect(() => {
    if (nfts) {
      if (nfts.length !== 0) {
        let currentInfo = staked.map((stk) => {
          return stk.nft;
        });
        const allowed = nfts
          .filter((item) => HASHLIST.includes(item.mintAddress.toBase58()))
          .filter((item) => currentInfo.includes(item.mintAddress.toBase58()))
          .map((nft) => {
            return nft.mintAddress.toBase58();
          });
        const newones = staked.filter((item) => allowed.includes(item.nft));
        setStakedItems(newones);
      } else {
        setStakedItems([]);
      }
    } else {
      setStakedItems([]);
    }
  }, [nfts, staked, stake]);

  
  React.useEffect(() => {
    const interval = setInterval(() => {
      if (stake) {
        if (stakedItems.length !== 0) {
          let total = 0;
          stakedItems.map((item) => {
            let countUp = Date.now() - item.updatedAt;
            let rewardPerSec = stake.nftGroups[0].amount / 24 / 60 / 60;
            let secs = countUp / 1000;
            let value = rewardPerSec * secs;
            total = total + value;
          });
          setTotal(total.toFixed(0));
        } else {
          setTotal(0);
        }
      } else {
        setTotal(0);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [stakedItems, stake]);

  const claim = async () => {
    let total = 0;
    stakedItems.map((item) => {
      let countUp = Date.now() - item.updatedAt;
      let rewardPerSec = stake.nftGroups[0].amount / 24 / 60 / 60;
      let secs = countUp / 1000;
      let value = rewardPerSec * secs;
      total = total + value;
    });
    let reward = total.toFixed(0);
    TRANSACTION_STAKE_CLAIM(stake, reward)
      .then(async (ok) => {
        if (ok) {
          let currentInfo = stakedItems.map((stk) => {
            return {
              nft: stk.nft,
              stakeName: stk.stakeName,
              updatedAt: Date.now(),
            };
          });
          let body = {
            wallet: user,
            info: currentInfo,
          };
          handleDB(body, user)
            .then(async (ok) => {
              await getBalance(
                new web3.PublicKey(user),
                stake.prize.mintAddress
              );
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const select = (address) => {
    let current = selected.filter((item) => item === address);
    if (current.length > 0) {
      setSelected(selected.filter((item) => item !== address));
    } else {
      setSelected(() => [...selected, address]);
    }
  };

  const submitNonCustodial = async (all) => {
    let full = stakedItems.map((item) => {
      return item.nft
    })
    let total = 0;
    if (all) {
      staked
      .filter((item) => full.includes(item.nft))
      .map((item) => {
        let countUp = Date.now() - item.updatedAt;
        let rewardPerSec = stake.nftGroups[0].amount / 24 / 60 / 60;
        let secs = countUp / 1000;
        let value = rewardPerSec * secs;
        total = total + value;
      });
    } else {
      stakedItems
      .filter((item) => selected.includes(item.nft))
      .map((item) => {
        let countUp = Date.now() - item.updatedAt;
        let rewardPerSec = stake.nftGroups[0].amount / 24 / 60 / 60;
        let secs = countUp / 1000;
        let value = rewardPerSec * secs;
        total = total + value;
      });
    }
    
    let reward = total.toFixed(0);
    if (reward >= 1) {
      TRANSACTION_STAKE_CLAIM(stake, reward)
        .then(async (ok) => {
          if (ok) {
            let currentInfo;
            if (all) {
              let full = stakedItems.map((item) => {
                return item.nft
              })
              currentInfo = staked
              .filter((item) => !full.includes(item.nft))
              .map((stk) => {
                return {
                  nft: stk.nft,
                  stakeName: stk.stakeName,
                  updatedAt: stk.updatedAt,
                };
              });
            } else {
              currentInfo = stakedItems
              .filter((item) => !selected.includes(item.nft))
              .map((stk) => {
                return {
                  nft: stk.nft,
                  stakeName: stk.stakeName,
                  updatedAt: stk.updatedAt,
                };
              });
            }
            let body = {
              wallet: user,
              info: currentInfo,
            };
            handleDB(body, user)
              .then(async (ok) => {
                await getNfts();
                setSelected([]);
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      let currentInfo;
      if (all) {
        let full = stakedItems.map((item) => {
          return item.nft
        })
        currentInfo = staked
        .filter((item) => !full.includes(item.nft))
        .map((stk) => {
          return {
            nft: stk.nft,
            stakeName: stk.stakeName,
            updatedAt: stk.updatedAt,
          };
        });
      } else {
        currentInfo = staked
        .filter((item) => !selected.includes(item.nft))
        .map((stk) => {
          return {
            nft: stk.nft,
            stakeName: stk.stakeName,
            updatedAt: stk.updatedAt,
          };
        });
      }
      
      let body = {
        wallet: user,
        info: currentInfo,
      };
      handleDB(body, user)
        .then(async (ok) => {
          await getNfts();
          setSelected([]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // const submit = async () => {
  //   let total = 0;
  //   staked
  //     .filter((item) => selected.includes(item.nft))
  //     .map((item) => {
  //       let countUpDate = new Date(item.updatedAt).getTime();
  //       let countUp = new Date().getTime() - countUpDate;
  //       let rewardPerSec = stake.nftGroups[0].amount / 24 / 60 / 60;
  //       let secs = countUp / 1000;
  //       let value = rewardPerSec * secs;
  //       total = total + value;
  //     });
  //   let body = [];
  //   selected.map((item) => {
  //     body.push({ nft: item });
  //   });
  //   TRANSACTION_STAKE_REMOVE(body, stake, total)
  //     .then((ok) => {
  //       if (ok) {
  //         let currentInfo = staked
  //           .filter((item) => !selected.includes(item.nft))
  //           .map((stk) => {
  //             return {
  //               nft: stk.nft,
  //               stakeName: stk.stakeName,
  //               updatedAt: stk.updatedAt,
  //             };
  //           });
  //         let body = {
  //           wallet: user,
  //           info: currentInfo,
  //         };
  //         handleDB(body, user)
  //           .then(async (ok) => {
  //             setTimeout(async () => {
  //               await getNfts();
  //               setSelected([]);
  //             }, 2000);
  //           })
  //           .catch((err) => {
  //             console.log(err);
  //           });
  //       } else {
  //         setIsLoading(false);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  return (
    <div className={classes.root}>
      {!match && (
        <Skeleton visible={isLoading} className={classes.header}>
          {/* {isLoading && <Overlay />} */}
          <Text className={classes.walletText}>
              RATE {stake ? (stake.nftGroups[0].amount * stakedItems.length).toFixed(0) : 0} / DAY
          </Text>
          <div className={classes.balanceWrapper}>
            <Text className={classes.balance}>EARNED:</Text>
            <Text className={classes.balanceNumber}>
              {total} {stake ? stake.prize.mintName : "$TOKEN"}
            </Text>
          </div>
        </Skeleton>
      )}
      {match && (
        <Skeleton visible={isLoading} className={classes.header}>
          <Flex sx={{ width: "100%" }} justify={"space-between"}>
            <Text className={classes.walletText}>
              RATE {stake ? (stake.nftGroups[0].amount * stakedItems.length).toFixed(0) : 0} / DAY
            </Text>
            <Text className={classes.balance}>EARNED:</Text>
          </Flex>
          <div className={classes.balanceWrapper}>
            <Text className={classes.balanceNumber}>
              {total} {stake ? stake.prize.mintName : "$TOKEN"}
            </Text>
          </div>
        </Skeleton>
      )}
      <Skeleton visible={isLoading} className={classes.selection}>
        <ScrollArea style={{ paddingRight: 16, height: 150, width: "100%" }}>
          {stakedItems && stakedItems.length === 0 && (
            <Center>
              <Text>0 items staked</Text>
            </Center>
          )}
          <SimpleGrid
            cols={4}
            spacing={"md"}
            breakpoints={[
              { maxWidth: 900, cols: 3, spacing: "sm" },
              { minWidth: 1920, cols: 4, spacing: "lg" },
            ]}
          >
            {stakedItems &&
              stakedItems.length !== 0 &&
              stakedItems.map((nft, index) => (
                <NftItem
                  action={() => select(nft.nft)}
                  selected={selected.includes(nft.nft)}
                  key={index}
                  nft={nft}
                />
              ))}
            {!stakedItems && <Loader />}
          </SimpleGrid>
        </ScrollArea>
        <div className={classes.buttonWrapper}>
          <Button
            disabled={selected.length === 0}
            onClick={() => submitNonCustodial(false)}
            className={cx(classes.button, {
              [classes.buttonActive]: selected.length !== 0,
            })}
          >
            
            Unstake
          </Button>
          <Button
            disabled={stakedItems.length === 0}
            onClick={() => submitNonCustodial(true)}
            className={cx(classes.buttonAll, {
              [classes.buttonActive]: stakedItems.length !== 0,
            })}
          >Unstake All</Button>
          <Button
            disabled={total < 1}
            onClick={claim}
            className={cx(classes.button, {
              [classes.buttonActive]: total >= 1,
            })}
          >
            Claim
          </Button>
        </div>
      </Skeleton>
    </div>
  );
};

export default StakeOut;
