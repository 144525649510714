import React from "react";
import { keypairIdentity, Metaplex } from "@metaplex-foundation/js";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { supabase } from "../Helpers/supabaseClient";
import * as web3 from "@solana/web3.js";
import { Workspace } from "../Workspace";
import HASHLIST from "../Helpers/hashlist.json";
import { LoadingOverlay, Overlay } from "@mantine/core";

export const AppContext = React.createContext({});
export const AppStorage = ({ children }) => {
  const [nfts, setNfts] = React.useState([]);
  const [staked, setStaked] = React.useState([]);
  const [allStakes, setAllStakes] = React.useState([]);
  const [stake, setStake] = React.useState({});
  const [user, setUser] = React.useState("");
  const [stakingBalance, setStakingBalance] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  
  const [updater, setUpdater] = React.useState(true);

  const {
    workspace,
    makeStakeVaultTx,
    makeStakeDepositTx,
    stakeTx,
    makeStakeClaimTx,
    unstakeTx,
  } = React.useContext(Workspace);

  const { connection } = useConnection();
  const { publicKey, connected } = useWallet();
  const operator = useWallet();

  const metaplex = Metaplex.make(connection);

  const getStakers = React.useCallback(async () => {
    let { data, error, status } = await supabase.from("stakers").select();

    if (error && status !== 406) {
      throw error;
    }

    if (data) {
      return data;
    }
  }, []);

  const getStakes = React.useCallback(async () => {
    let { data, error, status } = await supabase.from("stakes").select();

    if (error && status !== 406) {
      throw error;
    }

    if (data) {
      return data;
    }
  }, []);

  const getNft = async (mint) => {
    const nft = await metaplex
      .nfts()
      .findByMint({ mintAddress: new web3.PublicKey(mint) });
    let res = await fetch(nft.uri);
    let json = await res.json();
    return json;
  };

  const getNfts = async () => {
    await metaplex
      .nfts()
      .findAllByOwner({ owner: publicKey })
      .then((nfts) => {
        setNfts(nfts);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getBalance = async (addr, mint) => {
    let tokenAccount = await connection.getTokenAccountsByOwner(addr, {
      mint: new web3.PublicKey(mint),
    });
    if (tokenAccount.value.length !== 0) {
      let balance = await connection.getTokenAccountBalance(
        tokenAccount.value[0].pubkey
      );
      return balance.value.uiAmount.toFixed(0);
    } else {
      return 0.0;
    }
  };

  const fetchBar = React.useCallback( async () => {
    let stakes = await getStakers();
    let allStakes = [];
    let changes = [];
    stakes.map((item) => {
      if (item.info.length !== 0) {
        item.info.map((c) => {
          allStakes.push(c);
          if (isNaN(new Date(c.updatedAt).getTime())) {
            changes.push(item);
          }
        });
      }
    });
    let uniq = [...new Set(changes)];
    console.log(uniq);
    // if (updater) {
    //   let uniq = [...new Set(changes)];
    //   console.log(uniq.length, ` updates`);
    //   for (var i = 0; i < uniq.length ; i++) {
    //     let newInfo = uniq[i].info.map((item) => {
    //       if (!isNaN(new Date(item.updatedAt).getTime())) {
    //         return {
    //           nft: item.nft,
    //           stakeName: item.stakeName,
    //           updatedAt: new Date(item.updatedAt).getTime()
    //         }
    //       } else {
    //         return {
    //           nft: item.nft,
    //           stakeName: item.stakeName,
    //           updatedAt: new Date("3/29/2023, 09:00:00 AM").getTime()
    //         }
    //       }
          
    //     })
    //     let body = {
    //       wallet: uniq[i].wallet,
    //       info: newInfo
    //     }
    //     if (i ==  uniq.length - 1) {
    //       setUpdater(false);
    //     }
    //   }
    // }
    setAllStakes(allStakes);
  }, [])

  const fetchDB = React.useCallback(async (addr) => {
    setIsLoading(true);
    let stakedb = await getStakes();
    setStake(stakedb[0]);
    let stakes = await getStakers();
    let currentStakes = stakes.filter((staker) => staker.wallet == addr);
    let userStakes = currentStakes[0]?.info;
    if (userStakes) {
      if (userStakes.length !== 0) {
        let stakedMeta = await Promise.all(
          userStakes.map(async (item) => {
            let body = {
              ...item,
              ...(await getNft(item.nft)),
            };
            return body;
          })
        );
        setStaked(stakedMeta);
      } else {
        setStaked([]);
      }
    } else {
      setStaked([]);
    }
    setStakingBalance(
      await getBalance(new web3.PublicKey(addr), stakedb[0].prize.mintAddress)
    );
    setIsLoading(false);
  }, []);

  const handleDB = React.useCallback(async (body, user) => {
    setIsLoading(true);
    let stakes = await getStakers();
    let currentStakes = stakes.filter((staker) => staker.wallet == user);
    if (currentStakes.length !== 0) {
      let newBody = {
        id: currentStakes[0].id,
        ...body,
      };
      let { error } = await supabase.from("stakers").upsert(newBody);
      if (error) {
        setIsLoading(false);
        throw error;
      } else {
        console.log(`${currentStakes[0].id} updated`);
      }
    } else {
      let newBody = {
        ...body,
      };
      let { error } = await supabase.from("stakers").upsert(newBody);
      if (error) {
        setIsLoading(false);
        throw error;
      } else {
        console.log(`${currentStakes[0].id} updated`);
      }
    }
  }, []);

  // staking

  const TRANSACTION_STAKE_CREATE = async (stakePoolName) => {
    setIsLoading(true);
    let instructionSet = [];
    let signersSet = [];
    let instructions = await makeStakeVaultTx(stakePoolName, workspace);

    let transaction = new web3.Transaction();

    instructions.instruction.map((tx) => {
      instructionSet.push(tx);
    });
    instructions.signers.map((tx) => {
      signersSet.push(tx);
    });
    instructionSet.map((instruction) => {
      transaction.add(instruction);
    });

    let recentBlock = await connection.getLatestBlockhash();

    transaction.feePayer = operator.publicKey;
    transaction.recentBlockhash = recentBlock.blockhash;

    if (signersSet.length > 0) {
      transaction.sign(...signersSet);
    }

    return operator
      .signTransaction(transaction)
      .then(async (signedTransaction) => {
        const rawTransaction = signedTransaction.serialize();
        let options = {
          skipPreflight: true,
          commitment: workspace.commitment,
        };
        return connection
          .sendRawTransaction(rawTransaction, options)
          .then(async (signature) => {
            return await connection
              .confirmTransaction({
                blockhash: recentBlock.blockhash,
                lastValidBlockHeight: recentBlock.lastValidBlockHeight,
                signature: signature,
              })
              .then(() => {
                console.log("signature: ", signature);
                return true;
              })
              .catch((err) => {
                console.log("confirm transaction error: ", err);
                return false;
              });
          })
          .catch((err) => {
            console.log("send serialized transaction error: ", err);
            return false;
          });
      })
      .catch((err) => {
        console.log("user signature error: ", err);
        return false;
      });
  };

  const TRANSACTION_STAKE_DEPOSIT = async (stake, amount) => {
    setIsLoading(true);
    let instructionSet = [];
    let signersSet = [];
    let instructions = await makeStakeDepositTx(stake, workspace, amount);

    let transaction = new web3.Transaction();
    instructions.instruction.map((tx) => {
      instructionSet.push(tx);
    });
    instructions.signers.map((tx) => {
      signersSet.push(tx);
    });
    instructionSet.map((instruction) => {
      transaction.add(instruction);
    });

    let recentBlock = await connection.getLatestBlockhash();
    transaction.feePayer = operator.publicKey;
    transaction.recentBlockhash = recentBlock.blockhash;

    if (signersSet.length > 0) {
      console.log(signersSet);
      transaction.sign(...signersSet);
    }

    return operator
      .signTransaction(transaction)
      .then(async (signedTransaction) => {
        const rawTransaction = signedTransaction.serialize();
        let options = {
          skipPreflight: true,
          commitment: workspace.commitment,
        };
        return connection
          .sendRawTransaction(rawTransaction, options)
          .then(async (signature) => {
            return await connection
              .confirmTransaction({
                blockhash: recentBlock.blockhash,
                lastValidBlockHeight: recentBlock.lastValidBlockHeight,
                signature: signature,
              })
              .then(() => {
                console.log("signature: ", signature);
                return true;
              })
              .catch((err) => {
                console.log("confirm transaction error: ", err);
                return false;
              });
          })
          .catch((err) => {
            console.log("send serialized transaction error: ", err);
            return false;
          });
      })
      .catch((err) => {
        console.log("user signature error: ", err);
        return false;
      });
  };

  const TRANSACTION_STAKE_ADD = async (items, stake) => {
    setIsLoading(true);
    let instructionSet = [];
    let signersSet = [];
    return Promise.all(
      items.map(async (item) => {
        return await stakeTx(stake.stakeName, item.nft, workspace);
      })
    ).then(async (instructions) => {
      let transaction = new web3.Transaction();
      instructions.map((item) => {
        item.instruction.map((tx) => {
          instructionSet.push(tx);
        });
        item.signers.map((tx) => {
          signersSet.push(tx);
        });
      });
      instructionSet.map((instruction) => {
        transaction.add(instruction);
      });

      let recentBlock = await connection.getLatestBlockhash();
      transaction.feePayer = operator.publicKey;
      transaction.recentBlockhash = recentBlock.blockhash;

      if (signersSet.length > 0) {
        transaction.sign(...signersSet);
      }

      return operator
        .signTransaction(transaction)
        .then(async (signedTransaction) => {
          const rawTransaction = signedTransaction.serialize();
          let options = {
            skipPreflight: true,
            commitment: workspace.commitment,
          };
          return connection
            .sendRawTransaction(rawTransaction, options)
            .then(async (signature) => {
              return await connection
                .confirmTransaction({
                  blockhash: recentBlock.blockhash,
                  lastValidBlockHeight: recentBlock.lastValidBlockHeight,
                  signature: signature,
                })
                .then(() => {
                  console.log("signature: ", signature);
                  return true;
                })
                .catch((err) => {
                  console.log("confirm transaction error: ", err);
                  return false;
                });
            })
            .catch((err) => {
              console.log("send serialized transaction error: ", err);
              return false;
            });
        })
        .catch((err) => {
          console.log("user signature error: ", err);
          return false;
        });
    });
  };

  const TRANSACTION_STAKE_CLAIM = async (stake, amount) => {
    setIsLoading(true);
    let instructionSet = [];
    let signersSet = [];
    const { instruction, signers } = await makeStakeClaimTx(
      stake,
      workspace,
      amount
    );
    let transaction = new web3.Transaction();
    instruction.map((item) => {
      instructionSet.push(item);
    });
    signers.map((tx) => {
      signersSet.push(tx);
    });
    instructionSet.map((instruction) => {
      transaction.add(instruction);
    });

    let recentBlock = await connection.getLatestBlockhash();
    transaction.feePayer = operator.publicKey;
    transaction.recentBlockhash = recentBlock.blockhash;

    if (signersSet.length > 0) {
      transaction.sign(...signersSet);
    }

    return operator
      .signTransaction(transaction)
      .then( async (signedTransaction) => {
        const rawTransaction = signedTransaction.serialize();
        let options = {
          skipPreflight: true,
          commitment: workspace.commitment,
        };
        return connection
          .sendRawTransaction(rawTransaction, options)
          .then(async (signature) => {
            return await connection
              .confirmTransaction({
                blockhash: recentBlock.blockhash,
                lastValidBlockHeight: recentBlock.lastValidBlockHeight,
                signature: signature,
              })
              .then(() => {
                console.log("signature: ", signature);
                return true;
              })
              .catch((err) => {
                console.log("confirm transaction error: ", err);
                return false;
              });
          })
          .catch((err) => {
            console.log("send serialized transaction error: ", err);
            return false;
          });
      })
      .catch((err) => {
        console.log("user signature error: ", err);
        return false;
      });
  };

  const TRANSACTION_STAKE_REMOVE = async (items, stake, amount) => {
    setIsLoading(true);
    let instructionSet = [];
    let signersSet = [];
    if (amount >= 1) {
      const { instruction, signers } = await makeStakeClaimTx(
        stake,
        workspace,
        amount
      );
      instruction.map((item) => {
        instructionSet.push(item);
      });
      signers.map((tx) => {
        signersSet.push(tx);
      });
    }
    let transaction = new web3.Transaction();
    if (items.length !== 0) {
      return Promise.all(
        items.map(async (item) => {
          return await unstakeTx(stake.stakeName, item.nft, workspace);
        })
      ).then(async (instructions) => {
        if (instructions.length !== 0) {
          instructions.map((item) => {
            if (item.instruction.length !== 0) {
              item.instruction.map((tx) => {
                instructionSet.push(tx);
              });
            }
            if (item.signers.length !== 0) {
              item.signers.map((tx) => {
                signersSet.push(tx);
              });
            }
          });
        }
        instructionSet.map((instruction) => {
          transaction.add(instruction);
        });

        

        let recentBlock = await connection.getLatestBlockhash();
        transaction.feePayer = operator.publicKey;
        transaction.recentBlockhash = recentBlock.blockhash;

        if (signersSet.length > 0) {
          transaction.sign(...signersSet);
        }

        return operator
          .signTransaction(transaction)
          .then(async (signedTransaction) => {
            const rawTransaction = signedTransaction.serialize();
            let options = {
              skipPreflight: true,
              commitment: workspace.commitment,
            };
            return connection
              .sendRawTransaction(rawTransaction, options)
              .then(async (signature) => {
                return await connection
                  .confirmTransaction({
                    blockhash: recentBlock.blockhash,
                    lastValidBlockHeight: recentBlock.lastValidBlockHeight,
                    signature: signature,
                  })
                  .then(() => {
                    console.log("signature: ", signature);
                    return true;
                  })
                  .catch((err) => {
                    console.log("confirm transaction error: ", err);
                    return false;
                  });
              })
              .catch((err) => {
                console.log("send serialized transaction error: ", err);
                return false;
              });
          })
          .catch((err) => {
            console.log("user signature error: ", err);
            return false;
          });
      });
    }
  };

  React.useEffect(() => {
    if (connected && publicKey) {
      setUser(publicKey.toBase58());
      fetchDB(publicKey.toBase58());
      fetchBar();
      getNfts();
    }
    supabase.channel('staking').on('postgres_changes',
      { event: '*', schema: 'public', table: 'stakers' },
      (payload) => {
        fetchBar();
        if (payload.new.wallet === publicKey.toBase58()) {
          fetchDB(publicKey.toBase58());
        }
      }
    )
    .subscribe()
  }, [connected, publicKey]);

  return (
    <AppContext.Provider
      value={{
        nfts,
        staked,
        stake,
        user,
        stakingBalance,
        handleDB,
        getBalance,
        fetchDB,
        TRANSACTION_STAKE_CREATE,
        TRANSACTION_STAKE_DEPOSIT,
        TRANSACTION_STAKE_ADD,
        getNfts,
        TRANSACTION_STAKE_CLAIM,
        TRANSACTION_STAKE_REMOVE,
        allStakes,
        HASHLIST,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
