import { Container, createStyles, Image, Text } from "@mantine/core";
import { gsap } from "gsap";
import React from "react";
import RoadmapImage from "../Assets/Images/roadmap.png";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import RoadmapItem from "../Components/RoadmapItem";
import { useMediaQuery } from "@mantine/hooks";

gsap.registerPlugin(ScrollTrigger);

const useStyles = createStyles((theme) => ({
  container: {
    backgroundColor: `#464646`,
    flexShrink: 0,
    width: "100%",
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    position: "relative",
    gap: 80,
    [theme.fn.smallerThan("900")]: {
      padding: "80px 0",
    },
  },
  title: {
    fontSize: 200,
    textTransform: "uppercase",
    fontWeight: 700,
    zIndex: 1,
    color: "#FFCC00",
    [theme.fn.smallerThan("1401")]: {
      fontSize: 150,
    },
    [theme.fn.smallerThan("900")]: {
      fontSize: 50,
    },
  },
  imageWrapper: {
    zIndex: 0,
    position: "relative",
    width: "1600px",
    [theme.fn.smallerThan("1401")]: {
      width: "1200px",
    },
    [theme.fn.smallerThan("900")]: {
      width: "100vw",
      display: "flex",
      flexDirection: "column",
      gap: 80,
    },
  },
  image: {
    [theme.fn.smallerThan("900")]: {
      position: "absolute",
      zIndex: -1,
      objectFit: "cover",
      height: "100%",
      width: "100%",
      display: "block",
      overflow: "hidden",
    },
  },
  subtitleWrapper: {
    // width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: -40,
    [theme.fn.smallerThan("900")]: {
      marginTop: 0,
    },
  },

  subtitle: {
    display: "flex",
    gap: 10,
    fontSize: 36,
    fontWeight: 600,
    zIndex: 1,
    color: "white",
    [theme.fn.smallerThan("1401")]: {
      fontSize: 26,
    },
    [theme.fn.smallerThan("900")]: {
      fontSize: 18,
    },
  },
}));

const Roadmap = () => {
  const { classes, theme } = useStyles();

  React.useEffect(() => {
    gsap.to("#mile01", {
      scrollTrigger: {
        trigger: "#roadmap",
        start: "top top",
        end: window.innerWidth,
        scrub: 1,
      }, // start the animation when ".box" enters the viewport (once)
      opacity: 1,
    });

    gsap.to("#mile02", {
      scrollTrigger: {
        trigger: "#mile01",
        start: "top center",
        end: window.innerWidth,
        scrub: 1,
      }, // start the animation when ".box" enters the viewport (once)
      opacity: 1,
    });

    gsap.to("#mile03", {
      scrollTrigger: {
        trigger: "#mile02",
        start: "top center",
        end: window.innerWidth,
        scrub: 1,
      }, // start the animation when ".box" enters the viewport (once)
      opacity: 1,
    });

    gsap.to("#mile04", {
      scrollTrigger: {
        trigger: "#mile03",
        start: "top center",
        end: window.innerWidth,
        scrub: 1,
      }, // start the animation when ".box" enters the viewport (once)
      opacity: 1,
    });

    gsap.to("#mile05", {
      scrollTrigger: {
        trigger: "#mile04",
        start: "top center",
        end: window.innerWidth,
        scrub: 1,
      }, // start the animation when ".box" enters the viewport (once)
      opacity: 1,
    });

    gsap.to("#mile06", {
      scrollTrigger: {
        trigger: "#mile05",
        start: "top center",
        end: window.innerWidth,
        scrub: 1,
      }, // start the animation when ".box" enters the viewport (once)
      opacity: 1,
    });
  }, []);
  const matches = useMediaQuery("(max-width: 900px)");

  return (
    <Container className={classes.container} id="roadmap" size="100%">
      <div className={classes.wrapper}>
        <Text className={classes.title}>Roadmap</Text>
        <div className={classes.subtitleWrapper}>
          <Text className={classes.subtitle}>
            <Text
              className={classes.subtitle}
              sx={{ color: theme.colors.dark }}
            >
              研究室
            </Text>
            What we`ll do.
          </Text>
          {!matches && <Text className={classes.subtitle}>Chapter 2</Text>}
        </div>
      </div>

      {matches && (
        <div className={classes.imageWrapper}>
          <img src={RoadmapImage} className={classes.image} alt="" />
          <RoadmapItem
            here
            title="HECTICHEADS"
            milestone="01"
            items={[
              "SUPPLY: TBC",
              "WHITELIST: TBC",
              "MINT PRICE: 1 SOL",
              "PRESALE: SOLDOUT",
            ]}
          />
          <RoadmapItem
            title="UNKNOWN"
            milestone="02"
            items={["??????????", "??????????"]}
          />
          <RoadmapItem
            title="UNKNOWN"
            milestone="03"
            items={["??????????", "??????????"]}
          />
          <RoadmapItem
            title="UNKNOWN"
            milestone="04"
            items={["??????????", "??????????"]}
          />
          <RoadmapItem
            title="UNKNOWN"
            milestone="05"
            items={["??????????", "??????????"]}
          />
          <RoadmapItem
            title="UNKNOWN"
            milestone="06"
            items={["??????????", "??????????"]}
          />
        </div>
      )}
      {!matches && (
        <div className={classes.imageWrapper}>
          <Image src={RoadmapImage} w="100%" className={classes.image} />
          <RoadmapItem
            id="mile01"
            here
            style={{ position: "absolute", left: 80, top: "14%", opacity: 0 }}
            title="HECTICHEADS"
            milestone="01"
            items={[
              "SUPPLY: TBC",
              "WHITELIST: TBC",
              "MINT PRICE: 1 SOL",
              "PRESALE: SOLDOUT",
            ]}
          />
          <RoadmapItem
            id="mile02"
            style={{
              position: "absolute",
              right: 80,
              top: "37.5%",
              opacity: 0,
            }}
            title="UNKNOWN"
            milestone="02"
            items={["??????????", "??????????"]}
          />
          <RoadmapItem
            id="mile03"
            style={{ position: "absolute", left: 80, top: "50%", opacity: 0 }}
            title="UNKNOWN"
            milestone="03"
            items={["??????????", "??????????"]}
          />
          <RoadmapItem
            id="mile04"
            style={{ position: "absolute", right: 80, top: "65%", opacity: 0 }}
            title="UNKNOWN"
            milestone="04"
            items={["??????????", "??????????"]}
          />
          <RoadmapItem
            id="mile05"
            style={{ position: "absolute", left: 80, top: "80%", opacity: 0 }}
            title="UNKNOWN"
            milestone="05"
            items={["??????????", "??????????"]}
          />
          <RoadmapItem
            id="mile06"
            style={{ position: "absolute", right: 80, top: "90%", opacity: 0 }}
            title="UNKNOWN"
            milestone="06"
            items={["??????????", "??????????"]}
          />
        </div>
      )}
    </Container>
  );
};

export default Roadmap;
