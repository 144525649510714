import { Container, createStyles, Image, Text } from "@mantine/core";
import { gsap } from "gsap";
import React from "react";
import BurgerJimmy from "../Assets/Images/BurgerJimmy.png";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import Art from "../Assets/Images/poop.png";
import Utility from "../Assets/Images/skull2.png";
import NoEmpty from "../Assets/Images/cube.png";
import { useMediaQuery } from "@mantine/hooks";

gsap.registerPlugin(ScrollTrigger);

const useStyles = createStyles((theme) => ({
  root: {
    backgroundColor: `#00B89D`,
    width: "100%",
    minHeight: "100%",
    padding: "100px 0",
  },

  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    gap: 80,
    maxWidth: "1600px",
  },
  wrapper: {
    paddingTop: 40,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "1600px",
  },
  title: {
    fontSize: 200,
    textTransform: "uppercase",
    fontWeight: 700,
    zIndex: 1,
    color: theme.colorScheme === "dark" ? theme.colors.d[0] : theme.colors.d[0],
    [theme.fn.smallerThan("1401")]: {
      fontSize: 150,
    },
    [theme.fn.smallerThan("900")]: {
      fontSize: 50,
      lineHeight: "65px",
    },
  },
  subtitleWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: -40,
    [theme.fn.smallerThan("900")]: {
      marginTop: 0,
    },
  },

  subtitle: {
    display: "flex",
    gap: 10,
    fontSize: 36,
    fontWeight: 600,
    zIndex: 1,
    color: theme.colorScheme === "dark" ? theme.colors.l[0] : theme.colors.l[0],
    [theme.fn.smallerThan("1401")]: {
      fontSize: 26,
    },
    [theme.fn.smallerThan("900")]: {
      fontSize: 18,
    },
  },
  jimmy: {
    zIndex: 0,
    maxWidth: 2000,
    position: "absolute",
    left: -150,
    top: 0,
  },
  boxWrapper: {
    width: 650,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.fn.rgba(theme.colors.l[0], 0.75)
        : theme.fn.rgba(theme.colors.l[0], 0.75),
    gap: 60,
    paddingTop: 30,
    paddingBottom: 60,
    zIndex: 1,
    borderRadius: 22,
    [theme.fn.smallerThan("900")]: {
      width: "100%",
      padding: 10,
    },
  },
  itemWrapper: {
    width: "90%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    opacity: 0,
  },
  itemImage: {
    maxWidth: 100,
    [theme.fn.smallerThan("900")]: {
      maxWidth: 50,
    },
  },
  itemTitle: {
    color: theme.colorScheme === "dark" ? theme.colors.d[0] : theme.colors.d[0],
    fontSize: 50,
    fontWeight: 700,
    textAlign: "center",
    [theme.fn.smallerThan("1401")]: {
      fontSize: 30,
    },
    [theme.fn.smallerThan("900")]: {
      fontSize: 24,
    },
  },
  itemDesc: {
    color: theme.colorScheme === "dark" ? theme.colors.d[0] : theme.colors.d[0],
    fontSize: 16,
    fontWeight: 400,
    textAlign: "center",
    [theme.fn.smallerThan("900")]: {
      fontSize: 12,
    },
  },
}));

const items = [
  {
    image: Art,
    title: "Art & Design",
    desc: "One of the founders of Sakelab is a professional artist and designer. Every product that is created by the Lab is subject to a complex and researched creative process and in no way purchased from third parties.",
  },
  {
    image: Utility,
    title: "Cross-Art Utility",
    desc: "The Lab's goal is to bring its experience and professionalism to the Web3 world and leverage its connections to make its ideas a reality. ( Animated series, gaming, product/industrial design , car design , action figure design etc... ).",
  },
  {
    image: NoEmpty,
    title: "No empty promises",
    desc: "We do not want to make any kind of promises that we would not be able to support. We will announce utilities only when we have the assurance that we can meet them.",
  },
];

const LabItem = ({ title, desc, image, id }) => {
  const { classes } = useStyles();
  return (
    <div id={id} className={classes.itemWrapper}>
      <Image src={image} className={classes.itemImage} />
      <Text className={classes.itemTitle}>{title}</Text>
      <Text className={classes.itemDesc}>{desc}</Text>
    </div>
  );
};

const Laboratory = () => {
  const { classes, theme } = useStyles();

  React.useEffect(() => {
    gsap.to("#jimmy", {
      scrollTrigger: {
        trigger: "#lab",
        start: "top center",
        end: () => `+=${window.innerWidth * 3}`,
        scrub: 2,
      }, // start the animation when ".box" enters the viewport (once)
      y: 250,
      x: 200,
    });

    gsap.to("#item0", {
      scrollTrigger: {
        trigger: "#lab",
        start: "top center",
        end: window.innerWidth,
        scrub: 1,
      }, // start the animation when ".box" enters the viewport (once)
      opacity: 1,
    });
    gsap.to("#item1", {
      scrollTrigger: {
        trigger: "#item0",
        start: "top top",
        end: window.innerWidth,
        scrub: 1,
      }, // start the animation when ".box" enters the viewport (once)
      opacity: 1,
    });
    gsap.to("#item2", {
      scrollTrigger: {
        trigger: "#item1",
        start: "top top",
        end: window.innerWidth,
        scrub: 1,
      }, // start the animation when ".box" enters the viewport (once)
      opacity: 1,
    });
  }, []);
  const matches = useMediaQuery("(max-width: 900px)");

  return (
    <div className={classes.root} id="concept">
      <Container className={classes.container} id="lab" size="100%">
        <div className={classes.wrapper}>
          <Text className={classes.title}>Laboratory</Text>
          <div className={classes.subtitleWrapper}>
            {!matches && <Text className={classes.subtitle}>Chapter 1</Text>}
            <Text className={classes.subtitle}>
              <Text
                className={classes.subtitle}
                sx={{ color: theme.colors.dark }}
              >
                研究室
              </Text>
              What we do
            </Text>
          </div>
        </div>
        <Image
          id="jimmy"
          src={BurgerJimmy}
          w="100%"
          className={classes.jimmy}
        />
        <div className={classes.boxWrapper}>
          {items.map((item, index) => (
            <LabItem
              key={index}
              id={`item${index}`}
              title={item.title}
              desc={item.desc}
              image={item.image}
            />
          ))}
        </div>
      </Container>
    </div>
  );
};

export default Laboratory;
