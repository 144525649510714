import React from "react";
import { MantineProvider, Global, ColorSchemeProvider } from "@mantine/core";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import History from "./Pages/History";
import { AppStorage } from "./Context";
import { Workstorage } from "./Workspace";
import Danger from "./Assets/Fonts/Another-Danger-Demo.woff"

const App = () => {

  const [colorScheme, setColorScheme] = React.useState('light');
  const toggleColorScheme = () => setColorScheme('light');

  function CustomFonts() {
    return (
      <Global
        styles={[
          {
            '@font-face': {
              fontFamily: 'Another Danger',
              src: `url('${Danger}') format("woff")`,
              fontWeight: 500,
              fontStyle: 'normal',
            },
          },
        ]}
      />
    );
  }
  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      < MantineProvider
        theme={{
          colorScheme,
          // globalStyles: (theme) => ({

          //   body: {
          //     ...theme.fn.fontStyles(),
          //     backgroundColor: theme.white,
          //     color: theme.black,
          //   },

          // }),
          // colorScheme: 'light',
          fontFamily: "Inter, sans-serif",
          colors: {
            l: [
              "#FFFFFF",
              "#FFFFFF",
              "#FFFFFF",
              "#FFFFFF",
              "#FFFFFF",
              "#FFFFFF",
              "#FFFFFF",
              "#FFFFFF",
              "#FFFFFF",
              "#FFFFFF",
            ],
            d: [
              "#010101",
              "#010101",
              "#010101",
              "#010101",
              "#010101",
              "#010101",
              "#010101",
              "#010101",
              "#010101",
              "#010101",
            ]
          },
          primaryColor: "d",
          // h1: {
          //   fontFamily: "Inter",
          //   fontSize: 30,
          //   color: "#FEFEFE",
          // },
        }
        }
        withGlobalStyles
        withNormalizeCSS
      >
        <BrowserRouter>
          <CustomFonts />
          <Workstorage>
            <AppStorage>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/history" element={<History />} />
              </Routes>
            </AppStorage>
          </Workstorage>
        </BrowserRouter>
      </MantineProvider >
    </ColorSchemeProvider>
  );
};

export default App;
