import React from 'react';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { SolflareWalletAdapter, SolletExtensionWalletAdapter, CoinbaseWalletAdapter, PhantomWalletAdapter, BraveWalletAdapter, GlowWalletAdapter, TorusWalletAdapter } from '@solana/wallet-adapter-wallets';
import {
  WalletModalProvider,
} from '@solana/wallet-adapter-react-ui';
import App from './App';
require('@solana/wallet-adapter-react-ui/styles.css');

const Dapp = () => {
  const endpoint = React.useMemo(() => "https://flashy-wandering-dew.solana-mainnet.quiknode.pro/fac4fd5d806c9f9b692f3c95ca97b19d065187e4", []);
  const wallets = React.useMemo(() => [
    new SolflareWalletAdapter(),
    new SolletExtensionWalletAdapter(),
    new CoinbaseWalletAdapter(),
    new PhantomWalletAdapter(),
    new BraveWalletAdapter(),
    new GlowWalletAdapter(),
    new TorusWalletAdapter()
  ], []);

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <App />
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}


export default Dapp;
