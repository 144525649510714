import { Center, createStyles, Image, Text } from "@mantine/core";
import React from "react";
import Here from "../Assets/Images/wearehere.gif";

const useStyles = createStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",

    zIndex: 2,
    [theme.fn.smallerThan("900")]: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    },
  },
  number: {
    fontSize: 176,
    lineHeight: 1,
    fontWeight: 900,
    color: "white",
    [theme.fn.smallerThan("1401")]: {
      fontSize: 128,
    },
    [theme.fn.smallerThan("900")]: {
      fontSize: 50,
      alignSelf: "start",
      lineHeight: 1,
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: 10,
    [theme.fn.smallerThan("900")]: {
      alignItems: "center",
    },
  },
  title: {
    fontSize: 40,
    fontWeight: 700,
    lineHeight: 1,
    color: "white",
    [theme.fn.smallerThan("1401")]: {
      fontSize: 30,
    },
    [theme.fn.smallerThan("900")]: {
      fontSize: 18,
      lineHeight: "25px",
    },
  },
  list: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: 2,
  },
  listItem: {
    fontSize: 26,
    lineHeight: 1,
    fontWeight: 600,
    color: "white",
    [theme.fn.smallerThan("1401")]: {
      fontSize: 20,
    },
    [theme.fn.smallerThan("900")]: {
      fontSize: 14,
      lineHeight: "18px",
    },
  },
  numberWrapper: {
    display: "flex",
  },
}));

const RoadmapItem = ({ milestone, title, items, style, here, id }) => {
  const { classes, theme } = useStyles();

  return (
    <Center className={classes.root} id={id} sx={style}>
      <div className={classes.numberWrapper}>
        <Text className={classes.number}>{milestone}</Text>
        <div className={classes.content}>
          <Text className={classes.title}>{title}</Text>
          <div className={classes.list}>
            {items &&
              items.map((item, index) => (
                <Text key={index} className={classes.listItem}>
                  {item}
                </Text>
              ))}
          </div>
        </div>
      </div>
      {here && (
        <Image
          src={Here}
          sx={{
            maxWidth: 300,
          }}
        />
      )}
    </Center>
  );
};

export default RoadmapItem;
