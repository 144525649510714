import { Container, Image, createStyles } from "@mantine/core";
import React from "react";
import HeroMP4 from "../Assets/Videos/hero.mp4";
import Logo from "../Assets/Logos/logo.png";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
const useStyles = createStyles((theme) => ({}));
const Hero = () => {
  React.useEffect(() => {
    gsap.to("#video", {
      scrollTrigger: {
        trigger: "#sakelab",
        start: "top top",
        end: () => `+=${window.innerWidth * 3}`,
        scrub: 2,
      }, // start the animation when ".box" enters the viewport (once)
      y: 0,
      x: -1000,
    });
    video.current.setAttribute("autoplay", "autoplay");
  }, []);
  const { classes, theme } = useStyles();
  const video = React.useRef();

  return (
    <Container
      sx={{
        width: "100%",
        minHeight: "1200px",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        // paddingTop: 40,
        position: "relative",
        [theme.fn.smallerThan("1400")]: {
          minHeight: "768px",
        },
        [theme.fn.smallerThan("900")]: {
          minHeight: "100%",
        },
      }}
      id="hero"
      size="100%"
    >
      <div style={{ position: "relative" }}>
        <Image
          src={Logo}
          sx={{
            zIndex: 1,
            position: "absolute",
            top: 0,
            left: "20%",
            maxWidth: 420,
            [theme.fn.smallerThan("1401")]: {
              maxWidth: 350,
              left: "15%",
            },
            [theme.fn.smallerThan("900")]: {
              maxWidth: 150,
              left: 0,
            },
          }}
        />
        <video
          width="100%"
          loop
          autoPlay
          playsInline
          disablePictureInPicture
          controls={false}
          muted
          id="video"
          ref={video}
          style={{
            zIndex: 0,
          }}
        >
          <source src={HeroMP4} type="video/mp4" />
        </video>
      </div>
    </Container>
  );
};

export default Hero;
