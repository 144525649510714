import { createStyles, Skeleton, Text } from "@mantine/core";
import React from "react";
import { AppContext } from "../Context";
import StakeIn from "./StakeIn";
import StakeOut from "./StakeOut";

const useStyle = createStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 8,
  },
  loaderWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  stakingWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 30,
    [theme.fn.smallerThan("md")]: {
      flexDirection: "column",
    },
  },
  // loader
  loaderEmpty: {
    zIndex: 0,
    width: 512,
    height: 50,
    borderRadius: 40,
    border: `1px solid ${"black"}`,
    position: "relative",
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
  },

  loaderText: {
    fontSize: 24,
    fontWeight: 800,
    textTransform: "uppercase",
    textAlign: "center",
    width: "100%",
    position: "absolute",
    [theme.fn.smallerThan("900")]: {
      fontSize: 18,
    },
  },
}));

const Staking = () => {
  const { classes, theme } = useStyle();
  const [percentage, setPercentage] = React.useState(0);
  const { allStakes, HASHLIST, staked, isLoading } = React.useContext(AppContext);

  React.useEffect(() => {
    if (allStakes) {
      if (allStakes.length !== 0) {
        let currentPercentage = (allStakes.length * 100) / HASHLIST.length;
        setPercentage(currentPercentage.toFixed(2));
      } else {
        setPercentage(0.00);
      }
    }
  }, [allStakes, HASHLIST]);

  return (
    <div className={classes.root}>
      <div className={classes.loaderWrapper}>
        <Skeleton visible={isLoading} className={classes.loaderEmpty}>
          <div
            style={{
              zIndex: -1,
              width: `${percentage}%`,
              height: "100%",
              borderRadius: "0px 40px 40px 0px",
              top: 0,
              backgroundColor: "#EFB852",
            }}
          />
          <Text className={classes.loaderText}>{percentage}% STAKED</Text>
        </Skeleton>
      </div>
      <div className={classes.stakingWrapper}>
        <StakeIn />
        <StakeOut />
      </div>
    </div>
  );
};

export default Staking;
