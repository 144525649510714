import React from "react";
import {
  Image,
  createStyles,
  Center,
  Title,
  Text,
  Button,
  Container,
} from "@mantine/core";
import bg from "../Assets/Images/Founders.png";
import { Link } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  root: {
    position: "relative",
    color: theme.colors.dark,
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 10,
    paddingBottom: "50px",
  },
  container: {
    width: "50%",
    fontFamily: "Inter",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "100px 0",
    flexFlow: "column",
    color: theme.colors.dark,
    gap: "100px",
    [theme.fn.smallerThan("900")]: {
      width: "100%",
      gap: "50px",
      padding: "50px 0",
    },
  },
  imgWrapper: {
    width: "50%",
    zIndex: "5",
    display: "flex",
    alignItems: "end",
    justifyContent: "end",
  },

  wrapper: {
    paddingTop: 100,
    paddingBottom: 100,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.fn.smallerThan("900")]: {
      width: "100%",
      justifyContent: "start",
      alignItems: "start",
      padding: "100px 16px",
    },
  },
  title: {
    fontSize: 200,
    textTransform: "uppercase",
    fontWeight: 700,
    zIndex: 1,
    [theme.fn.smallerThan("1401")]: {
      lineHeight: "150px",
      fontSize: 150,
    },
    [theme.fn.smallerThan("900")]: {
      fontSize: 50,
      lineHeight: "72px",
    },
  },
  subtitleWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  subtitle: {
    fontSize: 36,
    display: "flex",
    gap: 10,
    lineHeight: "36px",
    fontWeight: 600,
    zIndex: 1,
    color: theme.colors.dark,
    [theme.fn.smallerThan("1401")]: {
      fontSize: 26,
      lineHeight: "26px",
    },
    [theme.fn.smallerThan("900")]: {
      fontSize: 18,
      lineHeight: "18px",
    },
  },
  imgTextWrapper: {
    display: "flex",
    width: "1600px",
    [theme.fn.smallerThan("1401")]: {
      width: "1200px",
    },
    [theme.fn.smallerThan("900")]: {
      width: "100%",
      flexFlow: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
}));
const Founders = () => {
  const { classes, theme } = useStyles();

  return (
    <div className={classes.root} id="team">
      <div className={classes.wrapper}>
        <Text className={classes.title}>FOUNDERS</Text>
        <div className={classes.subtitleWrapper}>
          <Text className={classes.subtitle}>
            ファウンダース{" "}
            <Text sx={{ color: "#9c9c9c" }} className={classes.subtitle}>
              Who we are.
            </Text>
          </Text>

          <Text
            className={classes.subtitle}
            sx={{
              [theme.fn.smallerThan("900")]: {
                display: "none",
              },
            }}
          >
            Chapter 3
          </Text>
        </div>
      </div>
      <div className={classes.imgTextWrapper}>
        <div className={classes.imgWrapper}>
          <Image src={bg} />
        </div>
        <div className={classes.container}>
          <Container
            sx={{
              alignSelf: "flex-start",
              width: "400px",
              margin: "0 0",
              [theme.fn.smallerThan("900")]: {
                width: "100%",
                alignSelf: "center",
              },
            }}
          >
            <Title
              sx={{
                fontFamily: "Inter",
                fontWeight: "700",
                fontSize: 30,
                lineHeight: "40px",
                [theme.fn.smallerThan("1401")]: {
                  fontSize: 26,
                },
              }}
            >
              MARCO
            </Title>
            <Title
              sx={{
                color: "#9c9c9c",
                fontSize: 18,
                paddingBottom: 20,
              }}
            >
              Founder
            </Title>
            <Text
              sx={{
                fontFamily: "Inter",
                fontWeight: "700",
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              Experienced full-time degen and fitness dickhead (he eats Greek
              yogurt that looks like cement and always takes videos and photos
              of himself as a slut). Engineer by the day and Marketing Manager
              by the rest of the time who can play many different roles in any
              given situation. He is a master of trial and error with a huge
              focus on errors. But he never gives up!
            </Text>
            <Text
              style={{
                fontFamily: "Inter",
                fontWeight: "700",
                fontStyle: "italic",
                fontSize: 18,
                paddingTop: "10px",
                lineHeight: "22px",
              }}
            >
              "I don`t know what the fuck we did wrong but one way or another
              we`re going to fix it! "
            </Text>
          </Container>
          <Container
            sx={{
              width: "400px",
              alignSelf: "flex-end",
              textAlign: "right",
              padding: "0 16px",
              margin: "0 0",
              [theme.fn.smallerThan("900")]: {
                width: "100%",
                alignSelf: "center",
                textAlign: "left",
              },
            }}
          >
            <Title
              sx={{
                fontFamily: "Inter",
                fontWeight: "700",
                fontSize: 30,
                lineHeight: "40px",
                [theme.fn.smallerThan("1401")]: {
                  fontSize: 26,
                },
              }}
            >
              ALESSANDRO
            </Title>
            <Title sx={{ color: "#9c9c9c", fontSize: 18, paddingBottom: 20 }}>
              Founder
            </Title>
            <Text
              sx={{
                fontFamily: "Inter",
                fontWeight: "700",
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              He always had a passion for conceptual art and character design
              but finally chose the automotive field to avoid ending up in the
              middle of the road painting asphalt with chalk, and absurdly he
              also seems to have been quite successful becoming a Lead Designer
              in Ferrari. After ten years he still decided to give it a try by
              starting SAKELAB together with the other asshole.
            </Text>
            <Text
              style={{
                fontFamily: "Inter",
                fontWeight: "700",
                fontStyle: "italic",
                fontSize: 18,
                paddingTop: "10px",
                lineHeight: "22px",
              }}
            >
              " Sooner or later we will be able to do something powerful. At the
              cost of ending up on the street eating cats"
            </Text>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Founders;
