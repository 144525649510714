import { Container, createStyles, Image, Text } from "@mantine/core";
import { gsap } from "gsap";
import React from "react";
import Concept from "../Assets/Images/Concept.png";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const useStyles = createStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    position: "relative",
    maxHeight: "100vh",
    height: "100%",
    color: theme.colorScheme === "dark" ? theme.colors.d[0] : theme.colors.d[0],
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    zIndex: 1,
    position: "absolute",
    height: "100%",
    left: "20%",
    top: 0,
    padding: "30px 0",
    [theme.fn.smallerThan("900")]: {
      padding: "10px 0",
    },
  },
  title: {
    fontSize: 130,
    fontWeight: 700,
    opacity: 0,
    lineHeight: 1.5,
    [theme.fn.smallerThan("1401")]: {
      fontSize: 80,
    },
    [theme.fn.smallerThan("900")]: {
      fontSize: 22,
    },
  },
  image: {
    width: "100%",
    objectFit: "contain",
    objectPosition: "50% 0%",
    [theme.fn.smallerThan("1401")]: {
      objectFit: "contain",
    },
    [theme.fn.smallerThan("900")]: {
      objectFit: "contain",
      width: "100%",
      objectPosition: "50% 50%",
    },
  },
  kanji: {
    fontSize: 72,
    fontWeight: 700,
    opacity: 0,
    lineHeight: 1.5,
    [theme.fn.smallerThan("1401")]: {
      fontSize: 52,
    },
    [theme.fn.smallerThan("900")]: {
      fontSize: 20,
    },
  },
}));

const Drawing = () => {
  const { classes } = useStyles();

  React.useEffect(() => {
    gsap.to("#text", {
      scrollTrigger: {
        trigger: "#drawing",
        start: "top center",
        end: window.innerWidth * 1.75,
        scrub: 1,
      }, // start the animation when ".box" enters the viewport (once)
      opacity: 1,
    });
  }, []);

  return (
    <Container className={classes.container} id="drawing" size="100%">
      <img src={Concept} className={classes.image} />
      <div className={classes.titleWrapper}>
        <Text id="text" className={classes.title}>
          "Drawing <br /> leaves no
          <br /> room for lies"
        </Text>
        <Text id="text" className={classes.kanji}>
          旅の恥はかきすて
        </Text>
      </div>
    </Container>
  );
};

export default Drawing;
