import { createStyles, Text } from "@mantine/core";
import { useWindowScroll } from "@mantine/hooks";
import gsap from "gsap";
import ScrollToPlugin from "gsap/dist/ScrollToPlugin";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import React from "react";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const useStyles = createStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
    alignItems: "flex-end",
    justifyContent: "center",
  },
  rootMobile: {
    paddingTop: 35,
    paddingLeft: 20,
    display: "flex",
    flexDirection: "column",
    gap: 25,
    alignItems: "flex-start",
    justifyContent: "center",
  },
  rootFlex: {
    color: "black",
    display: "flex",
    gap: 36,
    alignItems: "flex-start",
    justifyContent: "center",
    paddingRight: 40,
    [theme.fn.smallerThan(900)]: {
      display: "none",
    },
  },
  itemFlex: {
    color: "black",
    fontSize: 14,
    textDecoration: "none",
    fontWeight: 500,
    transition: "font-weight .2s ease-out",
    ":hover": {
      fontWeight: 700,
    },
  },
  item: {
    display: "flex",
    gap: 10,
    color: "transparent",
    fontSize: 14,
    transition: "color .2s ease-out",
    textDecoration: "none",
    ":hover": {
      color: "black",
    },
    svg: {
      color: "red",
    },
  },
  itemMobile: {
    fontSize: 20,
    textDecoration: "none",
    color: "black",
    fontWeight: 600,
  },
  itemActive: {
    color: "black",
    fontWeight: 700,
  },
}));

const SVG = ({ active }) => {
  if (active) {
    return (
      <svg width={12} viewBox="0 0 24 24">
        <circle cx="12" cy="12" r="10"></circle>
      </svg>
    );
  } else {
    return (
      <svg
        width={12}
        fill="transparent"
        stroke="#000"
        strokeWidth="2px"
        viewBox="0 0 24 24"
      >
        <circle cx="12" cy="12" r="10"></circle>
      </svg>
    );
  }
};

const UINavigation = ({ style, mobile, flex }) => {
  const { classes, cx } = useStyles();
  const [isActive, setActive] = React.useState();
  const [scroll, scrollTo] = useWindowScroll();

  React.useEffect(() => {
    let links = gsap.utils.toArray("nav a");
    links.forEach((a) => {
      let element = document.querySelector(a.getAttribute("href")),
        linkST = ScrollTrigger.create({
          trigger: element,
          start: "top top",
        });
      ScrollTrigger.create({
        trigger: element,
        start: "top center",
        end: "bottom center",
        onToggle: (self) => self.isActive && setActive(a.innerText),
      });
      a.addEventListener("click", (e) => {
        e.preventDefault();
        gsap.to(window, {
          duration: 1,
          scrollTo: linkST.start,
          overwrite: "auto",
        });
      });
    });
  }, []);

  if (!mobile) {
    if (!flex) {
      return (
        <nav className={classes.root} style={style}>
          <div>
            <a
              className={cx(classes.item, {
                [classes.itemActive]: isActive === undefined,
              })}
              href="#sakelab"
            >
              <Text>SAKELAB</Text>
              <SVG active={isActive === undefined} />
            </a>
          </div>
          <div>
            <a
              className={cx(classes.item, {
                [classes.itemActive]: isActive === "Concept",
              })}
              href="#concept"
            >
              <Text>Concept</Text>
              <SVG active={isActive === "Concept"} />
            </a>
          </div>
          <div>
            <a
              className={cx(classes.item, {
                [classes.itemActive]: isActive === "Roadmap",
              })}
              href="#roadmap"
            >
              <Text>Roadmap</Text>
              <SVG active={isActive === "Roadmap"} />
            </a>
          </div>
          <div>
            <a
              className={cx(classes.item, {
                [classes.itemActive]: isActive === "Projects",
              })}
              href="#projects"
            >
              <Text>Projects</Text>
              <SVG active={isActive === "Projects"} />
            </a>
          </div>
          <div>
            <a
              className={cx(classes.item, {
                [classes.itemActive]: isActive === "Team",
              })}
              href="#team"
            >
              <Text>Team</Text>
              <SVG active={isActive === "Team"} />
            </a>
          </div>
        </nav>
      );
    } else {
      return (
        <nav className={classes.rootFlex} style={style}>
          <div>
            <a
              className={cx(classes.itemFlex, {
                [classes.itemActive]: scroll.y === 0,
              })}
              href="#sakelab"
            >
              <Text>SAKELAB</Text>
            </a>
          </div>
          <div>
            <a
              className={cx(classes.itemFlex, {
                [classes.itemActive]: scroll.y !== 0 && isActive === "Concept",
              })}
              href="#concept"
            >
              <Text>Concept</Text>
            </a>
          </div>
          <div>
            <a
              className={cx(classes.itemFlex, {
                [classes.itemActive]: scroll.y !== 0 && isActive === "Roadmap",
              })}
              href="#roadmap"
            >
              <Text>Roadmap</Text>
            </a>
          </div>
          <div>
            <a
              className={cx(classes.itemFlex, {
                [classes.itemActive]: scroll.y !== 0 && isActive === "Projects",
              })}
              href="#projects"
            >
              <Text>Projects</Text>
            </a>
          </div>
          <div>
            <a
              className={cx(classes.itemFlex, {
                [classes.itemActive]: scroll.y !== 0 && isActive === "Team",
              })}
              href="#team"
            >
              <Text>Team</Text>
            </a>
          </div>
        </nav>
      );
    }
  } else {
    return (
      <nav className={classes.rootMobile} style={style}>
        <div>
          <a
            className={cx(classes.itemMobile, {
              [classes.itemActive]: scroll.y === 0,
            })}
            href="#sakelab"
          >
            <Text>SAKELAB</Text>
          </a>
        </div>
        <div>
          <a
            className={cx(classes.itemMobile, {
              [classes.itemActive]: scroll.y !== 0 && isActive === "Concept",
            })}
            href="#concept"
          >
            <Text>Concept</Text>
          </a>
        </div>
        <div>
          <a
            className={cx(classes.itemMobile, {
              [classes.itemActive]: scroll.y !== 0 && isActive === "Roadmap",
            })}
            href="#roadmap"
          >
            <Text>Roadmap</Text>
          </a>
        </div>
        <div>
          <a
            className={cx(classes.itemMobile, {
              [classes.itemActive]: scroll.y !== 0 && isActive === "Projects",
            })}
            href="#projects"
          >
            <Text>Projects</Text>
          </a>
        </div>
        <div>
          <a
            className={cx(classes.itemMobile, {
              [classes.itemActive]: scroll.y !== 0 && isActive === "Team",
            })}
            href="#team"
          >
            <Text>Team</Text>
          </a>
        </div>
      </nav>
    );
  }
};

export default UINavigation;
