import { MantineProvider, createStyles, Image } from "@mantine/core";
import React from "react";
import Discord from "../Assets/Logos/discord.svg";
import Twitter from "../Assets/Logos/twitter.svg";
import Instagram from "../Assets/Logos/instagram.svg";
import Linkedin from "../Assets/Logos/linkedin.svg";

const useStyles = createStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    overflowX: "hidden",
    width: "100%",
    height: 150,
    [theme.fn.smallerThan("900")]: {
      height: "100%",
    },
  },
  container: {
    width: "100%",
    position: "relative",
    color: theme.colors.dark,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: "1600px",
    fontSize: "14px",
    padding: "0 50px",
    [theme.fn.smallerThan("1401")]: {
      maxWidth: "1400px",
      width: "100%",
      display: "flex",
    },
    [theme.fn.smallerThan("900")]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: 20,
      fontSize: 10,
      textAlign: "center"
    },
  },
  svgWrapper: {
    display: "flex",
    gap: 20,
  },
  svg: {
    width: 50,
    [theme.fn.smallerThan("1401")]: {
      width: 15,
    },
  },

  link: {
    ":visited": {
      color: theme.colors.dark,
    },
  },
}));
const Footer = () => {
  const { classes, theme } = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div>
          <a
            href=""
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            日本酒 Terms & Conditions
          </a>
        </div>
        <div>
          © 2022 by <b>SAKELABS</b>
        </div>
        <div className={classes.svgWrapper}>
          <img src={Discord} className={classes.svg} />
          <img src={Linkedin} className={classes.svg} />
          <img src={Instagram} className={classes.svg} />
          <img src={Twitter} className={classes.svg} />
        </div>
      </div>
    </div>
  );
};

export default Footer;
