import { Container, createStyles, Image, Text } from "@mantine/core";
import { gsap } from "gsap";
import React from "react";
import DimensionVideo from "../Assets/Videos/dimension.mp4";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const useStyles = createStyles((theme) => ({
  container: {
    backgroundColor: "black",
    display: "flex",
    width: "100%",
    maxHeight: "100vh",
    position: "relative",
    height: "100%",
    color: theme.colorScheme === "dark" ? theme.colors.l[0] : theme.colors.l[0],
  },
  titleWrapper: {
    zIndex: 1,
    position: "absolute",
    right: "20%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    top: 0,
    padding: "30px 0",
    [theme.fn.smallerThan("900")]: {
      padding: "10px 0",
    },
  },
  title: {
    fontSize: 130,
    fontWeight: 700,
    lineHeight: 1.3,
    textAlign: "right",
    opacity: 0,
    [theme.fn.smallerThan("1401")]: {
      fontSize: 80,
    },
    [theme.fn.smallerThan("900")]: {
      fontSize: 22,
    },
  },
  kanji: {
    zIndex: 1,
    fontSize: 72,
    fontWeight: 700,
    opacity: 0,
    lineHeight: 1.5,
    textAlign: "right",
    [theme.fn.smallerThan("1401")]: {
      fontSize: 52,
    },
    [theme.fn.smallerThan("900")]: {
      fontSize: 20,
    },
  },
}));

const Dimension = () => {
  const { classes } = useStyles();
  const video = React.useRef();

  React.useEffect(() => {
    gsap.to("#text-dimension", {
      scrollTrigger: {
        trigger: "#dimension",
        start: "top center",
        end: window.innerWidth * 2,
        scrub: 1,
      }, // start the animation when ".box" enters the viewport (once)
      opacity: 1,
    });
    video.current.setAttribute("autoplay", "autoplay");
  }, []);

  return (
    <Container className={classes.container} id="dimension" size="100%">
      <div className={classes.titleWrapper}>
        <Text id="text-dimension" className={classes.title}>
          "but we are not
          <br /> bad with 3D
          <br /> Art too..."
        </Text>
        <Text id="text-dimension" className={classes.kanji}>
          見ぬが花
        </Text>
      </div>
      <video
        width="100%"
        loop
        autoPlay
        disablePictureInPicture
        controls={false}
        ref={video}
        muted
        playsInline
        style={{
          zIndex: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <source src={DimensionVideo} type="video/mp4" />
      </video>
    </Container>
  );
};

export default Dimension;
