import React from "react";
import {
  AspectRatio,
  Box,
  Container,
  createStyles,
  Image,
  ScrollArea,
  Text,
  Textarea,
  Title,
} from "@mantine/core";
import logo from "../Assets/Logos/brand.svg";
import Image1 from "../Assets/Images/Hectic.jpg";
import Image2 from "../Assets/Images/Opera_senza_titolo.jpg";
import Image3 from "../Assets/Images/Hectic-3.jpg";
import Image4 from "../Assets/Images/Hectic-4.jpg";
import Image5 from "../Assets/Images/Hectic-5.jpg";
import video1 from "../Assets/Videos/nfts.mp4";
import title from "../Assets/Images/title.gif";
import Head1 from "../Assets/Images/CrazyHead.jpg";
import Head2 from "../Assets/Images/CrazyHead2.jpg";
import Head3 from "../Assets/Images/CrazyHead3.jpg";
import Study from "../Assets/Images/sss.jpg";
import Legends from "../Assets/Images/Legends.png";
import Karibo from "../Assets/Images/KARIBO.jpg";
import KariboSubtitle from "../Assets/Images/Karibo.png";
import Bama from "../Assets/Images/BAMA.jpg";
import BamaSubtitle from "../Assets/Images/Bama.webp";
import Gherma from "../Assets/Images/GHERMA.jpg";
import GhermaSubtitle from "../Assets/Images/Gherma.png";
import Renga from "../Assets/Images/RENGA.jpg";
import RengaSubtitle from "../Assets/Images/Renga.png";
import { Link } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  root: {
    position: "relative",
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.d[0] : theme.colors.d[0],
    minHeight: "100vh",
    color: theme.colorScheme === "dark" ? theme.colors.l[0] : theme.colors.l[0],
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 150,
  },
  container: {
    position: "relative",
    maxWidth: 1000,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 30,

    [theme.fn.smallerThan("900")]: {
      minHeight: "100%",
      padding: "0 15px",
      width: "100%",
    },
  },

  logoWrapper: {
    position: "absolute",
    zIndex: 10,
    width: 1200,
    top: 30,
    left: 10,
    [theme.fn.smallerThan("1400")]: {
      left: 10,
      top: 30,
      width: "100%",
    },
    [theme.fn.smallerThan("900")]: {
      left: 10,
      top: 20,
      width: "80%",
    },
  },
  Logo: {
    img: {
      cursor: "pointer",
      transform: "rotate(0turn)",
      transition: "transform 1000ms ease-in-out",
      ":hover": {
        transform: "rotate(0.25turn)",
        transition: "transform 1000ms ease-in-out",
      },
    },
  },

  textWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    gap: 10,
    paddingBottom: 40,
  },
  imagetextWrapper: {
    display: "flex",
    justifyContent: "center",
    gap: 10,
    width: "100%",
    [theme.fn.smallerThan("900")]: {
      flexDirection: "column",
    },
  },

  Title: {
    fontFamily: "Inter",
    fontSize: 30,
    borderBottom: `solid ${theme.colors.light} 2px`,
    [theme.fn.smallerThan("900")]: {
      fontSize: 22,
    },
  },

  Subtitle: {
    alignSelf: "flex-end",
    fontSize: 22,
    fontWeight: 700,
    [theme.fn.smallerThan("900")]: {
      fontSize: 14,
    },
  },
  Text: {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: 16,
    lineHeight: 1.3,
  },

  TextItalic: {
    fontFamily: "Inter",
    fontStyle: "italic",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 1.3,
  },

  ImageWrapper: {
    position: "absolute",
    width: "80%",
    top: 20,
    zIndex: "10",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  flex1: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: 36,
    fontWeight: "700",
    paddingBottom: 40,
    [theme.fn.smallerThan("900")]: {
      fontSize: 14,
      lineHeight: 1,
    },
  },
  kanji: {
    color: "#4a4a4a",
  },

  headWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 40,
    padding: "40px 0",
  },
  nftWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 25,
    fontSize: 16,
    fontWeight: 700,
  },
}));

const History = () => {
  const { classes, theme } = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Link to="/" className={classes.logoWrapper}>
          <Image
            src={logo}
            className={classes.Logo}
            sx={{
              maxWidth: 50,
              [theme.fn.smallerThan("900")]: {
                maxWidth: 25,
              },
            }}
          />
        </Link>
        <div className={classes.container}>
          <div className={classes.ImageWrapper}>
            <Image src={title} fit="contain" />
            <Text className={classes.Subtitle}>CHAPTER 1</Text>
          </div>

          <Image src={Image1} />

          <div className={classes.textWrapper}>
            <Title className={classes.Title}>"HECTICHEADS"</Title>
            <Text className={classes.TextItalic}>
              It is known to all that Sakeworld was originated in 100 days by
              the primordial forces of the universe. During its creation these
              forces left traces that over the years took on a will and
              personality of their own. They took advantage of SakeWorld's
              environment to create their own abode and reside in it away from
              all other conscious life forms.
              <br /> With the enlargement of settlements in the wilderness these
              forces were slowly found one by one and called HecticHeads. Each
              had its own personality and disposition.
              <br /> Those who contained within themselves the forces of the
              purest good and those who were the expression of primordial evil.
              It was understood over the years that , by making a blood pact
              with them, a specific number of the chosen ones could harness
              their powers only and exclusively if the will of the group leader
              was the same as that of the HecticHeaf itself.
              <br /> It is still told today that not all the heads have been
              found. <br />
              The purest of heart are waiting for the chosen one.
              <br /> The dark ones, on the other hand, are waiting to find an
              Evil never seen before the course of millennia... an Evil to whom
              they can donate their own.
            </Text>
          </div>
          <div className={classes.textWrapper}>
            <Title className={classes.Title}>"The Great Discovery"</Title>
            <Text className={classes.TextItalic}>
              It had been as long as he could remember that Nerone heard a call
              coming from Worm Mountain , lair of the Blind men place of death.
              Only after years of preparation did Nerone his group succeed in
              reaching the place from which the voices came. A golden egg
              materialized on a small rock in the middle of absolute nowhere and
              hatching released a Dragonfruit HepticHead. Among the most
              powerful and ambiguous heads in existence. It is said that great
              deeds were accomplished throughout history thanks to the so-called
              " Wolfhead " .... both good and evil. But there were some who were
              waiting for nothing more than Nerone to hatch the egg . For the
              group it was time to escape.
            </Text>
          </div>
          <div className={classes.imagetextWrapper}>
            <Image src={Image2} className={classes.Image} />
            <Box
              className={classes.textWrapper}
              sx={{
                width: "40%",
                [theme.fn.smallerThan("900")]: {
                  width: "100%",
                },
              }}
            >
              <Title className={classes.Title}>"Run !!!"</Title>
              <Text className={classes.Text}>
                Escape had become necessary. The Blinded were too many, and the
                group was unable to deal with them all. Nero captained the
                escape while George and Kumo tried to defend HEPTIHEAD in every
                way possible. But something was happening... a mysterious force
                began to break free from the wolf and drain into the blood of
                our protagonists.
              </Text>
            </Box>
          </div>
          <Image src={Image3} />
          <div className={classes.textWrapper}>
            <Title className={classes.Title}>"The HepticPower"</Title>
            <Text className={classes.TextItalic}>
              Nerone felt he had a new strength, different from what he had
              gained so far from training. His feather boa took the form of a
              strange cloud with a mad expression and the power of a God. Large
              arms came out of it and with just the touch of his hands consumed
              the flesh of the Blinded. Large horns with fiery spikes grew in
              Giorgio, and the mozzarellas he now used to carry in his huge
              backpack came to life ... ready to explode. So this was the power
              of HecticHead?
            </Text>
          </div>
          <Image src={Image4} />
          <div className={classes.textWrapper}>
            <Title className={classes.Title}>"Hectic VS Hectic"</Title>
            <Text className={classes.Text}>
              The earth began to shake. Gigantic clouds of dense dust rose from
              the ground and carried by the wind at insane speeds almost
              scratched the skin. The giant Champion of the Blind appeared along
              with another HecticHead. A swamp "Kimbe". Things were beginning to
              get serious.
            </Text>
          </div>
          <div className={classes.imagetextWrapper}>
            <Box
              className={classes.textWrapper}
              sx={{
                width: "40%",
                [theme.fn.smallerThan("900")]: {
                  width: "100%",
                },
              }}
            >
              <Title className={classes.Title}>"Run !!!"</Title>
              <Text className={classes.Text}>
                Nerone was now exhausted. He needed rest. It was time for the
                second-in-command to take control of the situation. Kumo was in
                a mad frenzy. That power intoxicated him. The transformation was
                complete.
              </Text>
            </Box>
            <Image src={Image5} className={classes.Image} />
          </div>
        </div>
        <div>
          <Title
            sx={{
              fontSize: 98,
              color: "#FF0060",
              lineHeight: "132px",
              [theme.fn.smallerThan("900")]: {
                fontSize: 34,
                lineHeight: 1,
              },
            }}
          >
            THE COLLECTION
          </Title>
          <div className={classes.flex1}>
            <Text>Chapter 1</Text>
            <Text>
              <span className={classes.kanji}>コレクション</span> What we did.
            </Text>
          </div>
          <AspectRatio ratio={1}>
            <video src={video1} loop={true} controls={false} autoPlay={true} />
          </AspectRatio>
        </div>
        <div className={classes.headWrapper}>
          <Image src={Head1} />
          <Image src={Head2} />
          <Image src={Head3} />
        </div>
        <div className={classes.textWrapper}>
          <Title
            sx={{
              fontSize: 52,
              color: "#FF0060",
              [theme.fn.smallerThan("900")]: {
                fontSize: 32,
                lineHeight: 1,
              },
            }}
          >
            TRAITS STUDY
          </Title>
          <Text className={classes.Text}>
            Each drawn stroke is the result of conceptual and stylistic research
            (the drawing you see could easily be the one selected from other
            proofs of the same subject). Most likely the strokes you will find
            in this collection you have never seen.
          </Text>
          <Image src={Study} />
        </div>
        <div className={classes.textWrapper}>
          <Image src={Legends} />
          <div
            style={{ display: "flex", justifyContent: "end", width: "100%" }}
          >
            <Title sx={{ color: "#ff0000", fontSize: 44 }}>神様</Title>
            <Title sx={{ color: "#9c9c9c", fontSize: 32 }}>HEPTIC GODS</Title>
          </div>
          <div className={classes.nftWrapper}>
            <Image src={Karibo} />
            <Image src={KariboSubtitle} />
            <Text align="center">
              Evil God who holds the Thousand Souls of Ebony Bridge. From which
              an entire village was forced to jump during the first invasion of
              the Blind led by Burger Jimmy. Inviato da iPhone
            </Text>
          </div>
          <div className={classes.nftWrapper}>
            <Image src={Bama} />
            <Image src={BamaSubtitle} />
            <Text align="center">
              Became a God after the sealed pact with the great king of the
              heavens Kato ( The Gray ), king of the heavens and clouds. This
              alliance made it possible to win the Battle of Black Winter.
            </Text>
          </div>
          <div className={classes.nftWrapper}>
            <Image src={Gherma} />
            <Image src={GhermaSubtitle} />
            <Text align="center">
              Gherma offered her sight to the Three Seas God in exchange for the
              strength needed to enable her group to weather one of the worst
              storms ever.
            </Text>
          </div>
          <div className={classes.nftWrapper}>
            <Image src={Renga} />
            <Image src={RengaSubtitle} />
            <Text align="center">
              Gherma offered her sight to the Three Seas God in exchange for the
              strength needed to enable her group to weather one of the worst
              storms ever.
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default History;
