import WalletButton from "../Components/WalletButton";
import React from "react";
import Brand from "../Assets/Logos/brand.svg";
import {
  createStyles,
  Flex,
  Image,
  Text,
  Affix,
  Transition,
  Button,
  Burger,
  Drawer,
  Center,
} from "@mantine/core";
import { useDisclosure, useMediaQuery, useWindowScroll } from "@mantine/hooks";
import UINavigation from "../Components/UINavigation";
import Footer from "./Footer";
import { ButtonToggle } from "../Components/ButtonToggle";

const useStyles = createStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: 1080,
    padding: 15,
  },
  right: {
    display: "flex",
    alignItems: "center",
    gap: 40,
  },
  brand: {
    maxWidth: 38,
    [theme.fn.smallerThan(900)]: {
      display: "none",
    },
  },
  brandMobile: {
    maxWidth: 38,
  },
  burger: {
    [theme.fn.largerThan(900)]: {
      display: "none",
    },
  },
  menu: {
    borderBottom: "1px solid black",
  },
  mobileMenu: {
    display: "flex",
    flexDirection: "column",
    gap: 1,
    h1: {
      fontWeight: 900,
      fontSize: 14,
    },
    li: {
      listStyle: "none",
      fontSize: 14,
    },
  },
  valueWrapper: {
    display: "flex",
    gap: 5,
    fontSize: 12,
    fontWeight: 500,
    alignItems: "center",
    cursor: "pointer",
    [theme.fn.smallerThan("md")]: {
      gap: 2,
    },
  },
}));

const Header = () => {
  const { classes, theme } = useStyles();
  const [scroll, scrollTo] = useWindowScroll();
  const [mobileMenu, { toggle, close }] = useDisclosure(false);

  const matches = useMediaQuery("(max-width: 900px)");

  return (
    <div className={classes.root}>
      <div id="sakelab" className={classes.header}>
        <WalletButton />
        <div className={classes.right}>
          <Image className={classes.brand} src={Brand} />
          <Burger
            className={classes.burger}
            title="Mobile navigation"
            opened={mobileMenu}
            onClick={toggle}
            color={
              theme.colorScheme === "dark"
                ? theme.colors.d[0]
                : theme.colors.d[0]
            }
          />
          <div className={classes.menu}>
            <UINavigation flex />
          </div>
        </div>
        <Affix position={{ bottom: "50%", right: "5%" }}>
          <Transition transition="slide-up" mounted={!matches && scroll.y > 0}>
            {(transitionStyles) => <UINavigation style={transitionStyles} />}
          </Transition>
        </Affix>
        <Drawer
          opened={mobileMenu}
          onClose={close}
          position="left"
          size="sm"
          styles={(theme) => ({
            drawer: {
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.colors.l[0]
                  : theme.colors.l[0],
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.d[0]
                  : theme.colors.d[0],
            },

            body: {
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.colors.l[0]
                  : theme.colors.l[0],
            },
            closeButton: {
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.d[0]
                  : theme.colors.d[0],
            },
          })}
        >
          <div className={classes.mobileMenu}>
            <Center>
              <Image className={classes.brandMobile} src={Brand} />
            </Center>
            <UINavigation mobile />
          </div>
          {matches && <Footer />}
        </Drawer>
      </div>
    </div>
  );
};

export default Header;
