import React from "react";
import {
  Image,
  createStyles,
  Center,
  Title,
  Text,
  Button,
} from "@mantine/core";
import bg from "../Assets/Images/IMG_0206.PNG";
import { useMediaQuery } from "@mantine/hooks";
import { Link } from "react-router-dom";
import { AppContext } from "../Context";

const useStyles = createStyles((theme) => ({
  root: {
    position: "relative",
    color: theme.colors.light,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    height: "100%",
    [theme.fn.smallerThan("900")]: {
      padding: "50px 0",
    },
  },
  container: {
    fontFamily: "Inter",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "rgba(1, 1, 1, 0.2)",
    border: "solid 1px rgba(205, 205, 205, 0.2)",
    borderRadius: "20px",
    width: 1400,
    position: "absolute",
    zIndex: "5",
    padding: "30px 50px",
    [theme.fn.smallerThan("1401")]: {
      width: 1000,
    },
    [theme.fn.smallerThan("900")]: {
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(1, 1, 1, 0)",
      border: "none",
      borderRadius: "0px",
      alignSelf: "start",
    },
  },
  imgWrapper: {
    maxWidth: "1600px",
    [theme.fn.smallerThan("900")]: {
      maxWidth: "100%",
    },
  },
  image: {
    maxWidth: 1400,
    [theme.fn.smallerThan("1401")]: {
      maxWidth: 1000,
    },
    [theme.fn.smallerThan("900")]: {
      objectFit: "contain",
      width: "100%",
      objectPosition: "50% 100%",
    },
  },

  linkWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    [theme.fn.smallerThan("900")]: {
      justifyContent: "center",
    },
  },
  link: {
    zIndex: 40,
    fontSize: 40,
    fontWeight: "600",
    textDecoration: "none",
    letterSpacing: "initial",
    transition: "color 300ms ease, letter-spacing 300ms ease-in-out",
    color: theme.colorScheme === "dark" ? theme.colors.l[0] : theme.colors.l[0],

    ":visited": {
      color:
        theme.colorScheme === "dark" ? theme.colors.l[0] : theme.colors.l[0],
      [theme.fn.smallerThan("900")]: {
        color:
          theme.colorScheme === "dark" ? theme.colors.d[0] : theme.colors.d[0],
      },
    },
    ":hover": {
      color: "#ffcc00",
      transition: "color 300ms ease, letter-spacing 300ms ease-in-out",
      letterSpacing: "4px",
    },
    [theme.fn.smallerThan("900")]: {
      fontSize: "30",
      color:
        theme.colorScheme === "dark" ? theme.colors.d[0] : theme.colors.d[0],
    },
  },
}));
const Projects = () => {
  const { classes, theme } = useStyles();
  const matches = useMediaQuery("(max-width: 900px)");

  return (
    <div className={classes.root} id="projects">
      <div className={classes.imgWrapper}>
        <img src={bg} className={classes.image} />
      </div>
      <div className={classes.container}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Text
            sx={{
              fontSize: 500,
              lineHeight: "500px",
              fontWeight: "700",
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.l[0]
                  : theme.colors.l[0],
              [theme.fn.smallerThan("900")]: {
                fontSize: 100,
                lineHeight: "100px",
                color: "#FFCC00",
                alignSelf: "self-start",
              },
            }}
          >
            01
          </Text>
          <div>
            <Title
              sx={{
                borderBottom: `solid 2px ${theme.colors.l[0]}`,
                fontFamily: "Inter",
                fontWeight: "700",
                fontSize: 40,
                lineHeight: "40px",
                color:
                  theme.colorScheme === "dark"
                    ? theme.colors.l[0]
                    : theme.colors.l[0],
                [theme.fn.smallerThan("900")]: {
                  fontSize: 27,
                  lineHeight: "27px",
                  borderBottom: `none`,
                  color:
                    theme.colorScheme === "dark"
                      ? theme.colors.d[0]
                      : theme.colors.d[0],
                },
              }}
            >
              HECTICHEADS
            </Title>
            <Text
              sx={{
                paddingTop: 10,
                fontFamily: "Inter",
                fontWeight: "700",
                fontSize: 14,
                lineHeight: 1.3,
                color:
                  theme.colorScheme === "dark"
                    ? theme.colors.l[0]
                    : theme.colors.l[0],
                [theme.fn.smallerThan("900")]: {
                  fontSize: 9,
                  lineHeight: 1.3,
                  color:
                    theme.colorScheme === "dark"
                      ? theme.colors.d[0]
                      : theme.colors.d[0],
                },
              }}
            >
              Sakeworld is said to have been generated by an unknown power that
              during creation left traces. These little bits of power had many
              names but today they are identified as Hecticheads. Their power
              varies according to the amount of energy enclosed in them. Each is
              as different from the other as the powers they possess. Each
              Hectichead holds its own Hectichead and with it bestows incredible
              powers on each member of the team through a pact sealed directly
              with the head. It is said that there are still many Hectic Heads
              left on Sakeworld including the mother of them all.
            </Text>
            {!matches && (
              <div className={classes.linkWrapper}>
                <Link className={classes.link} to="/history">
                  JOIN IT
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
      {matches && (
        <div className={classes.linkWrapper}>
          <Link className={classes.link} to="/history">
            JOIN IT
          </Link>
        </div>
      )}
    </div>
  );
};

export default Projects;
