import { Container, createStyles, Text } from "@mantine/core";
import React from "react";
import Header from "../Views/Header";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import Hero from "../Views/Hero";
import Laboratory from "../Views/Laboratory";
import Drawing from "../Views/Drawing";
import Dimension from "../Views/Dimension";
import Roadmap from "../Views/Roadmap";
import Projects from "../Views/Projects";
import Founders from "../Views/Founders";
import Footer from "../Views/Footer";
import { useMediaQuery } from "@mantine/hooks";

gsap.registerPlugin(ScrollTrigger);

const useStyles = createStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    overflowX: "hidden",
    colorScheme: "light only",
    backgroundColor: theme.colorScheme === "dark" ? theme.colors.l[0] : theme.colors.l[0],
  },
}));

const Home = () => {
  // React.useEffect(() => {

  //   let sections = gsap.utils.toArray("#sliderer");
  //   let sectionsLeft = gsap.utils.toArray("#slidererleft");
  //   let sectionsNext = gsap.utils.toArray("#sliderernext");

  //   gsap.to(sections, {
  //     xPercent: -100 * (sections.length - 1),
  //     ease: "none",
  //     scrollTrigger: {
  //       trigger: "#container",
  //       start: "top top",
  //       pin: true,
  //       scrub: 0.5,
  //       end: () => `+=${window.innerWidth * 3}`,
  //     },
  //   });

  //   gsap.to(sectionsLeft, {
  //     xPercent: +100 * (sectionsLeft.length - 1),
  //     ease: "none",
  //     scrollTrigger: {
  //       trigger: "#containerleft",
  //       start: "top top",
  //       pin: true,
  //       scrub: 0.5,
  //       end: () => `+=${window.innerWidth * 3}`,
  //     },
  //   });
  // }, []);

  
  const { classes } = useStyles();
  const match = useMediaQuery("(min-width: 900px)");
  return (
    <div className={classes.root}>
      <Header />
      <div
      // style={{
      //   width: "auto",
      //   overflowX: "hidden",
      // }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100vw",
          }}
        >
          <Hero />
          <Laboratory />
          <Drawing />
          <Dimension />
          <Roadmap />
          <Projects />
          <Founders />
          {match && <Footer />}
        </div>
      </div>
    </div>
  );
};

export default Home;
