import {
  Box,
  Button,
  Center,
  createStyles,
  Image,
  Loader,
  Overlay,
  ScrollArea,
  SimpleGrid,
  Skeleton,
  Text,
} from "@mantine/core";
import * as web3 from "@solana/web3.js";
import React from "react";
import { AppContext } from "../Context";
import NftItem from "./NftItem";

const useStyle = createStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 260,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 8,
  },
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 12,
    paddingBottom: 12,
    border: `1px solid ${"black"}`,
    borderRadius: 12,
    maxHeight: 65,
    minHeight: 65,
    backgroundColor: "white",
  },
  selection: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 16,
    border: `1px solid ${"black"}`,
    borderRadius: 12,
    gap: 8,
  },
  balanceWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    flexDirection: "column",
  },
  walletText: {
    fontSize: 15,
    fontWeight: 700,
    textTransform: "uppercase",
    width: "100%",
  },
  balance: {
    fontSize: 13,
    fontWeight: 700,
    textTransform: "uppercase",
    [theme.fn.smallerThan("900")]: {
      fontSize: 8,
      fontWeight: 600,
    },
  },
  balanceNumber: {
    fontSize: 13,
    fontWeight: 700,
    textTransform: "uppercase",
    [theme.fn.smallerThan("900")]: {
      fontSize: 18,
      fontWeight: 700,
      lineHeight: 1,
    },
  },
  button: {
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 12,
    fontSize: 15,
    fontWeight: 800,
    textTransform: "uppercase",
    textAlign: "center",
    ":disabled": {
      border: `1px solid ${"black"}`,
      backgroundColor: "white",
      color: "black",
    },
    // width: 120,
    [theme.fn.smallerThan("900")]: {
      fontSize: 12,
    },
  },
  buttonAll: {
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 12,
    fontSize: 15,
    fontWeight: 800,
    textTransform: "uppercase",
    textAlign: "center",
    ":disabled": {
      border: `1px solid ${"black"}`,
      backgroundColor: "white",
      color: "black",
    },
    [theme.fn.smallerThan("900")]: {
      fontSize: 12,
    },
  },
  buttonActive: {
    backgroundColor: "black",
    color: "white",
    cursor: "pointer",
  },
  buttonWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 8,
  },
  slotWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: 10,
    [theme.fn.smallerThan("md")]: {
      flexDirection: "row",
    },
  },
  machineSlot: {
    width: 70,
    height: 70,
    textAlign: "center",
    border: "1px solid black",
    borderRadius: 6,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const MachineIn = () => {
  const { classes, cx } = useStyle();
  const {
    nfts,
    user,
    stake,
    HASHLIST,
    staked,
    handleDB,
    TRANSACTION_STAKE_ADD,
    getNfts,
    isLoading,
    setIsLoading,
  } = React.useContext(AppContext);
  const [selected, setSelected] = React.useState([]);
  const [items, setItems] = React.useState([]);

  const [inMachine, setInMachine] = React.useState([]);

  const select = (address) => {
    let current = selected.filter((item) => item === address);
    if (current.length > 0) {
      setSelected(selected.filter((item) => item !== address));
    } else {
      if (selected.length < 3) {
        setSelected((selected) => [...selected, address]);
      }
    }
  };

  const toMachine = (address) => {
    let current = inMachine.filter((item) => item === address);
    if (current.length > 0) {
      setInMachine(inMachine.filter((item) => item !== address));
    } else {
      if (inMachine.length < 3) {
        setInMachine(() => [...inMachine, address]);
      }
    }
  };

  const submit = (selected) => {
    if (inMachine.length === 3) {
      return;
    }
    if (inMachine.length === 2) {
      if (selected.length === 1) {
        setInMachine(() => [...inMachine, ...selected]);
        setSelected([]);
      }
    }
    if (inMachine.length === 1) {
      if (selected.length <= 2) {
        setInMachine(() => [...inMachine, ...selected]);
        setSelected([]);
      }
    }
    if (inMachine.length === 0) {
      setInMachine(() => [...selected]);
      setSelected([]);
    }
  };

  React.useEffect(() => {
    if (nfts) {
      if (nfts.length !== 0) {
        let currentInfo = staked.map((stk) => {
          return stk.nft;
        });
        const uris = nfts
          .filter((item) => HASHLIST.includes(item.mintAddress.toBase58()))
          .filter((item) => !currentInfo.includes(item.mintAddress.toBase58()))
          .map((nft) => {
            return nft.uri;
          });
        Promise.all(
          uris
            .filter((uri) => uri.startsWith("http"))
            .map(async (uri) => {
              let res = await fetch(uri);
              let json = await res.json();
              json.uri = uri;
              return json;
            })
        )
          .then((json) => {
            setItems(json);
          })
          .catch((err) => {
            console.log(err);
            setItems([]);
          });
      }
    }
  }, [nfts, staked]);

  return (
    <>
      <div className={classes.root}>
        {/* <Skeleton visible={isLoading} className={classes.header}> */}
        <Text className={classes.walletText}>
          WALLET {user ? user.substring(0, 5) + "..." : "..."}
        </Text>
        {/* </Skeleton> */}
        <Skeleton visible={isLoading} className={classes.selection}>
          <ScrollArea style={{ paddingRight: 16, height: 200, width: "100%" }}>
            {items && items.length === 0 && (
              <Center>
                <Text>0 items</Text>
              </Center>
            )}
            <SimpleGrid cols={2} spacing={"md"}>
              {items &&
                items.length !== 0 &&
                items.map(
                  (nft, index) =>
                    !inMachine.includes(
                      nfts
                        ?.filter((item) => item?.uri === nft?.uri)[0]
                        ?.mintAddress?.toBase58()
                    ) && (
                      <NftItem
                        action={() =>
                          select(
                            nfts
                              ?.filter((item) => item?.uri === nft?.uri)[0]
                              ?.mintAddress?.toBase58()
                          )
                        }
                        selected={selected.includes(
                          nfts
                            ?.filter((item) => item?.uri === nft?.uri)[0]
                            ?.mintAddress?.toBase58()
                        )}
                        key={index}
                        nft={nft}
                      />
                    )
                )}
              {!items && <Loader />}
            </SimpleGrid>
          </ScrollArea>
          <div className={classes.buttonWrapper}>
            <Button
              disabled={selected.length === 0}
              onClick={() => submit(selected)}
              className={cx(classes.button, {
                [classes.buttonActive]: selected.length > 0,
              })}
            >
              Select burgerable HHs
            </Button>
          </div>
        </Skeleton>
      </div>
      <div className={classes.slotWrapper}>
        {items &&
          items.length !== 0 &&
          items.map(
            (nft, index) =>
              inMachine.includes(
                nfts
                  ?.filter((item) => item?.uri === nft?.uri)[0]
                  ?.mintAddress?.toBase58()
              ) && (
                <NftItem
                  action={() =>
                    toMachine(
                      nfts
                        ?.filter((item) => item?.uri === nft?.uri)[0]
                        ?.mintAddress?.toBase58()
                    )
                  }
                  key={index}
                  nft={nft}
                  small
                />
              )
          )}

        {inMachine.length === 0 && (
          <div className={classes.machineSlot}>1st HH</div>
        )}
        {inMachine.length === 0 && (
          <div className={classes.machineSlot}>2nd HH</div>
        )}
        {inMachine.length === 0 && (
          <div className={classes.machineSlot}>3rd HH</div>
        )}
        {inMachine.length === 1 && (
          <div className={classes.machineSlot}>2rd HH</div>
        )}
        {inMachine.length === 1 && (
          <div className={classes.machineSlot}>3rd HH</div>
        )}
        {inMachine.length === 2 && (
          <div className={classes.machineSlot}>3rd HH</div>
        )}
      </div>
    </>
  );
};

export default MachineIn;
