import {
  Button,
  Flex,
  Image,
  Menu,
  Modal,
  Text,
  UnstyledButton,
  useMantineTheme,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useWallet } from "@solana/wallet-adapter-react";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import { IconWallet } from "@tabler/icons";
import React from "react";
import Staking from "./Staking";
import background from "../Assets/Images/skull2.png";
import burger from "../Assets/Images/burger.png";
import sake from "../Assets/Images/sake.png";
import pill from "../Assets/Images/pill.png";
import { AppContext } from "../Context";
import Machine from "./Machine";

const WalletButton = () => {
  const { visible, setVisible } = useWalletModal();
  const { connected, connecting, disconnecting, disconnect, publicKey } =
    useWallet();

  const handleWallets = React.useCallback(
    (event) => {
      if (!event.defaultPrevented) setVisible(!visible);
    },
    [setVisible, visible]
  );

  const handleLogout = React.useCallback(
    (event) => {
      if (!event.defaultPrevented) disconnect();
    },
    [connected]
  );

  const theme = useMantineTheme();

  const [opened, { open, close }] = useDisclosure(false);
  const [ isBurgerOpen, burgerModal ] = useDisclosure(false);
  const [ isJimmyOpen, jimmyModal ] = useDisclosure(false);
  const { stakingBalance } = React.useContext(AppContext);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        flexDirection: "column",
      }}
    >
      <div style={{
        display: "flex",
        justifyContent: "center",
      }}>
      {connected &&
      <UnstyledButton
        sx={{
          fontFamily: "inter",
          fontWeight: "700",
          color: "black",
          padding: 5,
          cursor: "auto",
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.l[0]
              : theme.colors.l[0],
        }}
      >
        {connected
          ? (publicKey.toBase58().substring(0, 4) + "...").toUpperCase()
          : connecting
          ? "Connecting...".toUpperCase()
          : disconnecting
          ? "Disconnecting...".toUpperCase()
          : "Connect Wallet".toUpperCase()}
      </UnstyledButton>
      }
      <Flex gap={{ base: 2, 900: 10, 1920: 15 }} align="center">
        {connected && (
          <Flex
            gap={{ base: 10, 900: 15, 1920: 20 }}
            sx={{
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.d[0]
                  : theme.colors.d[0],
              [theme.fn.smallerThan("900")]: {
                fontSize: 10,
              },
            }}
          >
            <Flex align={"center"} gap={{ base: 1, 900: 2, 1920: 4 }}>
              <Image
                src={burger}
                sx={{
                  img: {
                    maxWidth: 22,
                    [theme.fn.smallerThan("900")]: {
                      maxWidth: 18,
                    },
                  },
                }}
              />
              <Text>:0</Text>
            </Flex>
            <Flex align={"center"} gap={{ base: 1, 900: 2, 1920: 4 }}>
              <Image
                src={pill}
                sx={{
                  img: {
                    maxWidth: 22,
                    [theme.fn.smallerThan("900")]: {
                      maxWidth: 18,
                    },
                  },
                }}
              />
              <Text>:{stakingBalance ? stakingBalance : 0}</Text>
            </Flex>
            <Flex align={"center"} gap={{ base: 1, 900: 2, 1920: 4 }}>
              <Image
                src={sake}
                sx={{
                  img: {
                    maxWidth: 22,
                    [theme.fn.smallerThan("900")]: {
                      maxWidth: 18,
                    },
                  },
                }}
              />
              <Text>:0</Text>
            </Flex>
          </Flex>
        )}
      </Flex>
      </div>
      <Menu
        transition="pop-top-right"
        position="top-start"
        width={200}
        styles={(theme) => ({
          dropdown: {
            border: "none",
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.l[0]
                : theme.colors.l[0],
            ":hover": { backgroundColor: theme.colors.light },
          },
        })}
      >
        <Menu.Target>
          {connected ? (
            <div
              style={{
                fontFamily: "Another Danger",
                fontSize: 26,
                borderRadius: 0,
                marginLeft: -15,
                paddingLeft: 20,
                cursor: "pointer",
                color:
                  theme.colorScheme === "dark"
                    ? theme.colors.d[0]
                    : theme.colors.d[0],
                ":disabled": {
                  color: "black",
                },
              }}
            >
              THE LABORATORY
            </div>
          ) : (
            <UnstyledButton
              sx={{
                fontFamily: "inter",
                fontWeight: "700",
                color: "black",
                padding: 5,
                backgroundColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.l[0]
                    : theme.colors.l[0],
              }}
            >
              {connected
                ? (publicKey.toBase58().substring(0, 4) + "...").toUpperCase()
                : connecting
                ? "Connecting...".toUpperCase()
                : disconnecting
                ? "Disconnecting...".toUpperCase()
                : "Connect Wallet".toUpperCase()}
            </UnstyledButton>
          )}
        </Menu.Target>
        {connected ? (
          <Menu.Dropdown>
            <Menu.Item
              sx={{
                fontSize: 16,
                fontWeight: 800,
                borderLeft: "2px solid black",
                borderRadius: 0,
                color:
                  theme.colorScheme === "dark"
                    ? theme.colors.d[0]
                    : theme.colors.d[0],
              }}
              disabled
            >
              <Text>1 How it Work?</Text>
            </Menu.Item>
            <Menu.Item
              sx={{
                fontSize: 16,
                fontWeight: 800,
                borderLeft: "2px solid black",
                borderRadius: 0,
                color:
                  theme.colorScheme === "dark"
                    ? theme.colors.d[0]
                    : theme.colors.d[0],
              }}
              onClick={open}
            >
              <Text>2 Pills Machine</Text>
            </Menu.Item>
            <Menu.Item
              sx={{
                fontSize: 16,
                fontWeight: 800,
                borderLeft: "2px solid black",
                borderRadius: 0,
                color:
                  theme.colorScheme === "dark"
                    ? theme.colors.d[0]
                    : theme.colors.d[0],
              }}
              onClick={burgerModal.open}
              disabled
            >
              <Text>3 Burger Machine</Text>
            </Menu.Item>
            <Menu.Item
              sx={{
                fontSize: 16,
                fontWeight: 800,
                borderLeft: "2px solid black",
                borderRadius: 0,
                color:
                  theme.colorScheme === "dark"
                    ? theme.colors.d[0]
                    : theme.colors.d[0],
              }}
              onClick={jimmyModal.open}
              disabled
            >
              <Text>4 Burger Jimmy</Text>
            </Menu.Item>
            <Menu.Item
              sx={{
                fontSize: 16,
                fontWeight: 800,
                borderLeft: "2px solid black",
                borderRadius: 0,
                color:
                  theme.colorScheme === "dark"
                    ? theme.colors.d[0]
                    : theme.colors.d[0],
              }}
              onClick={handleLogout}
            >
              <Text>5 Log Out</Text>
            </Menu.Item>
          </Menu.Dropdown>
        ) : (
          <Menu.Dropdown>
            <Menu.Item
              onClick={handleWallets}
              rightSection={
                <Text
                  size="xs"
                  transform="uppercase"
                  weight={700}
                  color="dimmed"
                >
                  Select Wallets
                </Text>
              }
            />
          </Menu.Dropdown>
        )}
        <Modal
          opened={opened}
          onClose={close}
          overlayOpacity={0.1}
          overlayColor="white"
          overlayBlur={0}
          withCloseButton
          title="PILLS MACHINE"
          styles={(theme) => ({
            root: {},
            inner: {
              left: "25%",
              top: 50,
              [theme.fn.smallerThan("1366")]: {
                left: "20%",
                top: 50,
              },
              [theme.fn.smallerThan("900")]: {
                left: 0,
                top: 0,
              },
            },
            modal: {
              width: "fit-content",
              backgroundImage: `url(${background})`,
              backgroundSize: "contain",
              backgroundPosition: "50% 50%",
              backgroundRepeat: "no-repeat",
              backgroundBlendMode: "screen",
              maxWidth: 1100,
              minWidth: 1100,
              borderRadius: "18px",
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.fn.rgba(theme.colors.l[0], 0.9)
                  : theme.fn.rgba(theme.colors.l[0], 0.9),

              border: "1px solid black",
              [theme.fn.smallerThan("1600")]: {
                minWidth: 900,
                maxWidth: 900,
              },
              [theme.fn.smallerThan("900")]: {
                minWidth: "80vw",
                maxWidth: "80vw",
              },
            },
            header: {
              display: "flex",
              justifyContent: "center",
            },
            title: {
              fontSize: 64,
              lineHeight: 1,
              textTransform: "uppercase",
              fontWeight: 800,
              [theme.fn.smallerThan("900")]: {
                fontSize: 24,
              },
            },
            close: {
              ":hover": { backgroundColor: "transparent" },
              position: "absolute",
              left: "100%",
              top: 0,
              color: "black",
              svg: { width: 25, height: 25 },
            },
          })}
        >
          <Staking />
        </Modal>
        <Modal
          opened={isBurgerOpen}
          onClose={burgerModal.close}
          overlayOpacity={0.1}
          overlayColor="white"
          overlayBlur={0}
          withCloseButton
          title="BURGER MACHINE"
          styles={(theme) => ({
            root: {},
            inner: {
              left: "25%",
              top: 50,
              [theme.fn.smallerThan("1366")]: {
                left: "20%",
                top: 50,
              },
              [theme.fn.smallerThan("900")]: {
                left: 0,
                top: 0,
              },
            },
            modal: {
              width: "fit-content",
              backgroundImage: `url(${background})`,
              backgroundSize: "contain",
              backgroundPosition: "50% 50%",
              backgroundRepeat: "no-repeat",
              backgroundBlendMode: "screen",
              maxWidth: 1100,
              minWidth: 1100,
              borderRadius: "18px",
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.fn.rgba(theme.colors.l[0], 0.9)
                  : theme.fn.rgba(theme.colors.l[0], 0.9),

              border: "1px solid black",
              [theme.fn.smallerThan("1600")]: {
                minWidth: 900,
                maxWidth: 900,
              },
              [theme.fn.smallerThan("900")]: {
                minWidth: "80vw",
                maxWidth: "80vw",
              },
            },
            header: {
              display: "flex",
              justifyContent: "center",
            },
            title: {
              fontSize: 64,
              lineHeight: 1,
              textTransform: "uppercase",
              fontWeight: 800,
              [theme.fn.smallerThan("900")]: {
                fontSize: 24,
              },
            },
            close: {
              ":hover": { backgroundColor: "transparent" },
              position: "absolute",
              left: "100%",
              top: 0,
              color: "black",
              svg: { width: 25, height: 25 },
            },
          })}
        >
          <Machine />
        </Modal>
        <Modal
          opened={isJimmyOpen}
          onClose={jimmyModal.close}
          overlayOpacity={0.1}
          overlayColor="white"
          overlayBlur={0}
          withCloseButton
          title="BURGER JIMMY"
          styles={(theme) => ({
            root: {},
            inner: {
              left: "25%",
              top: 50,
              [theme.fn.smallerThan("1366")]: {
                left: "20%",
                top: 50,
              },
              [theme.fn.smallerThan("900")]: {
                left: 0,
                top: 0,
              },
            },
            modal: {
              width: "fit-content",
              backgroundImage: `url(${background})`,
              backgroundSize: "contain",
              backgroundPosition: "50% 50%",
              backgroundRepeat: "no-repeat",
              backgroundBlendMode: "screen",
              maxWidth: 1100,
              minWidth: 1100,
              borderRadius: "18px",
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.fn.rgba(theme.colors.l[0], 0.9)
                  : theme.fn.rgba(theme.colors.l[0], 0.9),

              border: "1px solid black",
              [theme.fn.smallerThan("1600")]: {
                minWidth: 900,
                maxWidth: 900,
              },
              [theme.fn.smallerThan("900")]: {
                minWidth: "80vw",
                maxWidth: "80vw",
              },
            },
            header: {
              display: "flex",
              justifyContent: "center",
            },
            title: {
              fontSize: 64,
              lineHeight: 1,
              textTransform: "uppercase",
              fontWeight: 800,
              [theme.fn.smallerThan("900")]: {
                fontSize: 24,
              },
            },
            close: {
              ":hover": { backgroundColor: "transparent" },
              position: "absolute",
              left: "100%",
              top: 0,
              color: "black",
              svg: { width: 25, height: 25 },
            },
          })}
        >
          burguer jimmy
        </Modal>
      </Menu>
    </div>
  );
};

export default WalletButton;
